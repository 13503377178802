import React from 'react'
import * as S from './styles'

export function EmptyState() {
  return (
    <S.Container>
      <span>Nenhum registro encontrado.</span>
    </S.Container>
  )
}
