import palette from 'theme/palette'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px 0',
    '& .MuiInputBase-root': {
      background: palette.white,
    },
  },
  loading: {
    color: palette.primary.light,
  },
}))

export default useStyles
