import React from 'react'
import Alert from 'components/Alert'
import PropTypes from 'prop-types'
import { Box, Button } from '@material-ui/core'

export const ChangeAlert = ({
  open = false,
  width = '100%',
  message = '',
  direction = 'center',
  onDeny = () => { },
  onAccept = () => { },
}) => {

  const directions = {
    left: 'flex-end',
    center: 'center',
    right: 'flex-start',
  }

  return open && (
    <Box position="relative" display="flex" zIndex={2} justifyContent={directions[direction]}>
      <Box width={width} position="absolute" paddingY={1}>
        <Alert
          variant="warning"
          message={(
            <Box marginLeft={2}>
              {message || 'Essa alteração não poderá ser desfeita, deseja seguir em frente?'}
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button size="small" variant="text" color="inherit" onClick={() => onDeny()}>
                  Não
                </Button>
                <Button size="small" variant="text" color="inherit" onClick={() => onAccept()}>
                  Sim
                </Button>
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  )
}

ChangeAlert.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.any,
  message: PropTypes.string,
  direction: PropTypes.string,
  onDeny: PropTypes.func,
  onAccept: PropTypes.func,
}