import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: auto;

  p,
  span {
    font-size: 14px;
    color: #707072;
    margin-bottom: 8px;
  }

  span {
    display: block;
  }

  .changes {
    margin-top: 16px;
  }
`
