import React from 'react'
import { Context } from './Context'
import * as S from './styles'

export function Content({ logs }) {
  return (
    <S.Container>
      <Context logs={logs} />
    </S.Container>
  )
}
