import styled from 'styled-components'
import palette from 'theme/palette'

export const Wrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: ${palette.text.secondary};
  }

  button[data-active='false']:hover {
    text-decoration: underline;
  }

  button[data-active='true'] {
    color: ${palette.text.primary};
    cursor: default;
  }
`
