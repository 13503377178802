import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
`

export const Title = styled.h4`
  color: #707070;
  font-family: 'NexaLight';
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.06px;
  margin-bottom: 18px;

  &:not(:first-child) {
    margin-top: 18px;
  }
`

export const Quote = styled.div`
  margin-bottom: 8px;

  p,
  span,
  b {
    font-size: 14px;
    color: #707070;
    font-family: 'NexaLight';
    margin-bottom: 8px;
  }
`
