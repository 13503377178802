import API from 'api'

export const solLeadsService = {
  async importLeads(formData) {
    const response = await API.post('/solleads/import-leads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  },
  async solLeadsHistory(id) {
    const response = await API.get(`/solar/solleads-history/${id}`)
    return response
  },
  async solLeadsHistoryCompany(id, params) {
    const response = await API.get(`/gestao/solleads-history-company/${id}`, {
      params,
    })
    return response
  },
}
