import API from 'api'

export const logsService = {
  async getUserDetailLogs(userId, params) {
    const response = await API.get(`logs/user/${userId}`, {
      params,
    })
    return response.data
  },
}
