import { Header } from './Header'
import { Content } from './Content'
import { EmptyState } from './EmptyState'
import { Loading } from './Loading'
import { Container } from './Container'
import { Paper } from './Paper'

export const LogPart = {
  Header,
  Content,
  EmptyState,
  Loading,
  Container,
  Paper,
}
