let barOptions = {
    legend: {
        display: false,
        color: 'gray',
        fontColor: 'gray',
        labels: {
            color: 'gray',
            fontColor: 'gray'
        }
    },
    scales: {
        xAxes: [
            {
                ticks: {
                    color: 'gray',
                    fontColor: 'gray'
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false
                }
            }
        ],
        yAxes: [
            {
                type: 'linear',
                display: false,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    color: 'gray',
                    fontColor: 'gray',
                    beginAtZero: true,
                    // callback(value) {
                    // return Number(value).toLocaleString('pt-br', {
                    //     style: 'currency',
                    //     currency: 'BRL'
                    // })
                    // }
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    tickMarkLength: false,
                    zeroLineColor: 'transparent'
                }
            }
        ]
    },
        tooltips: {
        callbacks: {
            title: () => {},
            // label(tooltipItem) {
            // return tooltipItem.yLabel.toLocaleString('pt-br', {
            //     style: 'currency',
            //     currency: 'BRL'
            // })
            // }
        },
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1
    },
    maintainAspectRatio: false,
    responsive: true
}

let barOptionsCurrency = {
    legend: {
        display: false,
        color: 'gray',
        fontColor: 'gray',
        labels: {
            color: 'gray',
            fontColor: 'gray'
        }
    },
    scales: {
        xAxes: [
            {
                ticks: {
                    color: 'gray',
                    fontColor: 'gray'
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false
                }
            }
        ],
        yAxes: [
            {
                type: 'linear',
                display: false,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    color: 'gray',
                    fontColor: 'gray',
                    beginAtZero: true,
                    callback(value) {
                        return Number(value).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                    }
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    tickMarkLength: false,
                    zeroLineColor: 'transparent'
                }
            }
        ]
    },
        tooltips: {
        callbacks: {
            title: () => {},
                label(tooltipItem) {
                return tooltipItem.yLabel.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                })
            }
        },
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1
    },
    maintainAspectRatio: false,
    responsive: true
}

let lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false
    },
        scales: {
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    // callback(value) {
                    //     return Number(value).toLocaleString('pt-br', {
                    //         style: 'currency',
                    //         currency: 'BRL'
                    //     })
                    // },
                    autoSkip: true,
                    stepSize: 3000,
                    beginAtZero: true
                },
                gridLines: {
                    borderDash: [5, 5]
                }
            }
        ],
        xAxes: [
            {
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    tickMarkLength: false,
                    zeroLineColor: 'transparent'
                }
            }
        ]
        },
        tooltips: {
        // callbacks: {
        //     title: () => {},
        //         label(tooltipItem) {
        //         return tooltipItem.yLabel.toLocaleString('pt-br', {
        //             style: 'currency',
        //             currency: 'BRL'
        //         })
        //     }
        // },
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1
    }
}

let lineOptionsCurrency = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false
    },
        scales: {
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    callback(value) {
                        return Number(value).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                    },
                },
                gridLines: {
                    borderDash: [5, 5]
                }
            }
        ],
        xAxes: [
            {
                gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: false,
                    tickMarkLength: false,
                    zeroLineColor: 'transparent'
                }
            }
        ]
        },
        tooltips: {
        callbacks: {
            title: () => {},
                label(tooltipItem) {
                return tooltipItem.yLabel.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                })
            }
        },
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1
    }
}

exports.barOptions = barOptions;
exports.lineOptions = lineOptions;
exports.barOptionsCurrency = barOptionsCurrency;
exports.lineOptionsCurrency = lineOptionsCurrency;