import React, { useEffect } from 'react'
import { TextField, MenuItem } from '@material-ui/core'
import * as S from './styles'
import { USER_TYPE_OPTIONS, USER_TYPE_ENUM } from '../../constants'
import { useFetchProvidersSelectFaaS } from './hooks/useFetchProvidersSelectFaaS'

export function ChooseProvider({
  providerID,
  setProviderID,
  userType,
  setUserType,
}) {
  const canChooseProvider = Boolean(userType === USER_TYPE_ENUM.DISTRIBUTOR)

  const { providerOptions } = useFetchProvidersSelectFaaS()

  useEffect(() => {
    if (userType === USER_TYPE_ENUM.INTEGRATOR) setProviderID(null)
  }, [userType])

  return (
    <S.Container>
      <TextField
        select
        size="small"
        variant="outlined"
        label="Tipo de conta"
        value={userType}
        onChange={(_, { props }) => {
          setUserType(props.value)
        }}
      >
        {USER_TYPE_OPTIONS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        size="small"
        variant="outlined"
        label="Nome do fornecedor"
        value={providerID}
        disabled={!canChooseProvider}
        onChange={(_, { props }) => {
          setProviderID(props.value)
        }}
      >
        {providerOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </S.Container>
  )
}
