import { Logs } from 'components/Logs'
import { useMessage } from 'hooks/useMessage'
import React, { useEffect, useState } from 'react'
import { logsService } from 'services/logsService'
import { INITIAL_LOG } from './constants'

export function UserModificationLogs({ userId }) {
  const [data, setData] = useState(INITIAL_LOG)
  const [page, setPage] = useState(INITIAL_LOG.current_page)
  const [isLoading, setIsLoading] = useState(true)

  const { onErrorMessage } = useMessage()

  useEffect(() => {
    setIsLoading(true)

    const params = {
      page,
    }

    logsService
      .getUserDetailLogs(userId, params)
      .then((response) => setData(response))
      .catch(() =>
        onErrorMessage(
          'Ocorreu um erro ao tentar obter os registros de alterações.',
        ),
      )
      .finally(() => setIsLoading(false))
  }, [page, userId])

  return (
    <Logs data={data} isLoading={isLoading} page={page} setPage={setPage} />
  )
}
