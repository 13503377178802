export const USER_TYPE_ENUM = {
  INTEGRATOR: 'integrator',
  DISTRIBUTOR: 'distributor',
}

export const USER_TYPE_OPTIONS = [
  {
    label: 'Integrador',
    value: USER_TYPE_ENUM.INTEGRATOR,
  },
  {
    label: 'Distribuidor',
    value: USER_TYPE_ENUM.DISTRIBUTOR,
  },
]
