import { useEffect, useState } from 'react'
import { useSearchParams } from './useSearchParams'

export const usePagination = (data, activeSearchParams = false) => {
  const { defineOneParam, fetchParam } = useSearchParams()

  const currentPage = activeSearchParams ? fetchParam('page') : 1

  const [pagination, setPagination] = useState({
    current_page: currentPage || 1,
  })

  useEffect(() => {
    setPagination((state) => ({
      ...state,
      ...data,
    }))
  }, [data])

  const goToPage = (page) => {
    if (activeSearchParams) {
      defineOneParam('page', page)
    }

    setPagination({
      ...pagination,
      current_page: page,
    })
  }

  const handleNextPage = () => {
    goToPage(Number(pagination.current_page) + 1)
  }

  const handleBackPage = () => {
    goToPage(Number(pagination.current_page) - 1)
  }

  const handleLastPage = () => {
    goToPage(pagination.last_page)
  }

  const handleFirstPage = () => {
    goToPage(1)
  }

  return {
    pagination,
    handleNextPage,
    handleBackPage,
    handleLastPage,
    handleFirstPage,
  }
}
