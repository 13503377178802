import React from 'react'
import { formatDateAndTime } from 'utils/formats'
import { formatReal } from 'utils/dataFormat'
import { List } from 'components/List'
import { Pagination } from 'components/Pagination'
import { useSolLeads } from './hooks/useSolLeads'
import * as S from './styles'

export function SolLeads({ integratorId }) {
  const {
    leadsHistory,
    messageHistory,
    handleBackPage,
    handleFirstPage,
    handleLastPage,
    handleNextPage,
  } = useSolLeads({ integratorId })

  return (
    <>
      <List.Root>
        <List.Column>
          <List.Cell>ID</List.Cell>
          <List.Cell>Nome</List.Cell>
          <List.Cell>E-mail</List.Cell>
          <List.Cell>Valor de conta</List.Cell>
          <List.Cell>Data de envio</List.Cell>
          <List.Cell>Status SolLeads</List.Cell>
          <List.Cell>Último status</List.Cell>
        </List.Column>
        {messageHistory && (
          <List.Block position="bottom">
            <S.Message>{messageHistory}</S.Message>
          </List.Block>
        )}
        {!messageHistory &&
          leadsHistory?.data?.map((lead) => (
            <List.Row key={lead.id}>
              <List.Cell>{lead.id}</List.Cell>
              <List.Cell>{lead.name}</List.Cell>
              <List.Cell>{lead.email}</List.Cell>
              <List.Cell>
                {formatReal(lead.energy_bill_value, { static: true })}
              </List.Cell>
              <List.Cell>{formatDateAndTime(lead.status_enviado)}</List.Cell>
              <List.Cell>{lead.ultimo_status}</List.Cell>
              <List.Cell>
                {formatDateAndTime(lead.ultimo_status_data)}
              </List.Cell>
            </List.Row>
          ))}
      </List.Root>
      <S.Paginate>
        <Pagination
          pages={leadsHistory}
          onFirstPage={handleFirstPage}
          onBackPage={handleBackPage}
          onNextPage={handleNextPage}
          onLastPage={handleLastPage}
        />
      </S.Paginate>
    </>
  )
}
