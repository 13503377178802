import React from 'react'
import * as S from './styles'

export function Root({ children, ...props }) {
  return (
    <S.Container {...props}>
      <S.List>{children}</S.List>
    </S.Container>
  )
}
