import React from 'react'
import * as S from './styles'

export function Block({ children, position, ...props }) {
  return (
    <S.Block position={position} {...props}>
      {children}
    </S.Block>
  )
}
