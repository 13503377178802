import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import useStyles from './styles'

export function InstantSearch({
  time,
  variant,
  placeholder,
  noOptions,
  search,
  options = [],
  searchFor = [],
  onSearch,
  setSearch,
}) {
  const [clear, setClear] = useState(false)

  const classes = useStyles()

  function handleChange(_, value) {
    setSearch(value)
  }

  function handleSearch({ target }) {
    setClear(true)

    const searching = setTimeout(() => {
      onSearch(target.value)
      setClear(false)
    }, time)

    if (clear) {
      clearTimeout(searching)
    }
  }

  function handleOptions(filtered, { inputValue }) {
    return filtered.reduce((acc, option) => {
      Object.entries(option).forEach(([key, item]) => {
        if (inputValue && searchFor.includes(key)) {
          const value = String(item)
          if (value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
            acc.push({ key, value })
          }
        }
      })
      return acc
    }, [])
  }

  function handleRenderOption({ value }) {
    return value
  }

  return (
    <Autocomplete
      clearOnBlur={false}
      className={classes.root}
      value={search}
      options={options}
      onChange={handleChange}
      noOptionsText={noOptions}
      filterOptions={handleOptions}
      renderOption={handleRenderOption}
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          variant={variant}
          placeholder={placeholder}
          onChange={handleSearch}
          {...(clear && {
            InputProps: {
              endAdornment: (
                <CircularProgress className={classes.loading} size={20} />
              ),
            },
          })}
        />
      )}
    />
  )
}

InstantSearch.propTypes = {
  time: PropTypes.number,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  noOptions: PropTypes.string,
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
}

InstantSearch.defaultProps = {
  time: 1000,
  variant: 'outlined',
  placeholder: 'Digite para pesquisar...',
  noOptions: 'Pesquisa não encontrada.',
}
