export const INITIAL = {
  UPDATE_REGISTRATION: {
    nome: '',
    cpfResponsavel: '',
    celular: '',
    telefone: '',
    email: '',
    razaoSocial: '',
    nomeFantasia: '',
    cnpj: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
  },
  SEFAZ_REGISTRATION: {
    nome_empresarial: '',
    cnpj: '',
    inscricao_estadual: '',
    tipo_inscricao: '',
    situacao_cnpj: '',
    situacao_ie: '',
    nome_fantasia: '',
    data_situacao_cadastral: '',
    data_inicio_atividade: '',
    data_fim_atividade: '',
    regime_tributacao: '',
    informacao_ie_como_destinatario: '',
    porte_empresa: '',
    cnae_principal: {
      code: '',
      text: '',
    },
    uf: '',
    municipio: '',
    logradouro: '',
    complemento: '',
    cep: '',
    numero: '',
    bairro: '',
  },
  DIFFERENT_REGISTRATION: [],
}

export const OPTIONS = {
  STATES: [],
  BLOCK: ['Não', 'Sim'],
}
