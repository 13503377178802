import React from 'react'
import { BasicInfo } from './BasicInfo'

export function Context({ logs }) {
  return (
    <div>
      {logs?.length > 0 &&
        logs?.map((log) => (
          <>
            <BasicInfo logs={log} />

            <div className="changes">
              <span>
                <strong>Alterações:</strong>
              </span>
              {log?.changes?.length === 0 && <p>Nenhuma alteração.</p>}
              {log?.changes?.map((change) => (
                <p key={change}>{change}</p>
              ))}
            </div>
          </>
        ))}
    </div>
  )
}
