import React from 'react'
import { LogPart } from './components'

export function Logs({
  page,
  setPage,
  isLoading,
  hasPadding = true,
  canShowBorder = true,
  data,
}) {
  const HAS_LOGS = data && data?.data.length > 0

  if (!HAS_LOGS && !isLoading)
    return (
      <LogPart.Paper hasPadding={hasPadding} canShowBorder={canShowBorder}>
        <LogPart.EmptyState />
      </LogPart.Paper>
    )

  const { last_page, next_page_url } = data
  const hasMoreLogs = next_page_url !== null

  return (
    <LogPart.Paper hasPadding={hasPadding} canShowBorder={canShowBorder}>
      <LogPart.Header
        page={page}
        setPage={setPage}
        lastPage={last_page}
        hasMoreLogs={hasMoreLogs}
      />

      {isLoading && <LogPart.Loading />}
      {!isLoading && <LogPart.Content logs={data?.data} />}
    </LogPart.Paper>
  )
}
