import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  > button {
    width: 160px;
    text-transform: none;
    align-self: end;
  }
`
