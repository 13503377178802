import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, TableHead, TextField } from '@material-ui/core'
import { Page } from 'components'
import moment from 'moment'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import './DashboardDefault.css'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import API from '../../api'
import { Header, DashGraphComponent } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  paperSmRedefinir: {
    '@media (min-width:350px)': {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      borderRadius: '0px',
      border: 'none',
      width: '95%',
      height: '75%',
      marginLeft: '2.5%',
      marginTop: '25%',
      display: 'flex',
      alignItems: 'center',
    },
    '@media (min-width:780px)': {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
      borderRadius: '0px',
      border: 'none',
      width: '50%',
      height: '50%',
      marginLeft: '25%',
      marginTop: '25vh',
      display: 'flex',
      alignItems: 'center',
    },
  },
  picker: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    padding: '6px 3px 3px 3px',
    width: '100%',
    fontSize: '13px',
    border: '1px solid #D9D9D9',
  },
  calendar: {
    backgroundColor: '#FFFFFF',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    border: 'none',
    height: '80vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    width: '70vw',
  },
}))

function DashboardDefault() {
  const classes = useStyles()

  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [valuePicker, setValuePicker] = useState([
    new Date('2021-10-10T00:00:00'),
    new Date(),
  ])

  const [dataList, setDataList] = useState([])
  const [dataGraph, setDataGraph] = useState([])

  const [info, setInfo] = useState('proposals')

  useEffect(() => {
    getData()
  }, [valuePicker, info])

  const getData = () => {
    API.get(
      `/backoffice/data-plataform?inicial_date=${moment(valuePicker[0]).format(
        'YYYY-MM-DD',
      )}&final_date=${moment(valuePicker[1]).format(
        'YYYY-MM-DD',
      )}&info=${info}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.getItem('tokenGestao'),
        },
      },
    )
      .then((res) => {
        setDataList(res.data.dataList)
        setDataGraph(res.data.dataGraph)
        setOpenBackdrop(false)
      })
      .catch((err) => {})
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Header />

      <br />

      <Grid spacing={2} container>
        <Grid item md={3} xs={12}>
          <DateRangePicker
            onChange={setValuePicker}
            value={valuePicker}
            locale="pt-BR"
            clearIcon={null}
            className={classes.picker}
            calendarClassName={classes.calendar}
            minDate={new Date('Oct 10, 2021')}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            SelectProps={{
              native: true,
            }}
            select
            label="Info"
            size="small"
            style={{ backgroundColor: '#FFF', borderRadius: '8px' }}
            value={info}
            onChange={(e) => {
              setInfo(e.target.value)
            }}
          >
            <option value="proposals">Propostas</option>
            <option value="quotations">Cotações</option>
            <option value="orders">Pedidos</option>
            <option value="users">Usuários</option>
          </TextField>
        </Grid>
        <Grid item md={7} xs={12} />
        <Grid item md={12} xs={12}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Descrição </TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '60%' }}>
                      {data?.label}
                    </TableCell>
                    <TableCell>{data?.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {dataGraph.map((item) => (
          <Grid item md={item.size} xs={12}>
            <Paper elevation={1} style={{ height: '100%', padding: '30px' }}>
              <Typography
                variant="h5"
                style={{ fontFamily: 'NexaLight', color: '#707072' }}
              >
                {item.title}
              </Typography>
              <br />
              <DashGraphComponent
                multiple={item.multiple}
                format={item.format}
                title={item.title}
                data={item.data}
                labels={item.lables}
                type={item.type}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Page>
  )
}

export default DashboardDefault
