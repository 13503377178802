import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  paperSmall: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    border: 'none',
    height: 'auto',
    maxHeight: '40vh',
    overflowY: 'auto',
    width: '40vw',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    border: 'none',
    height: '80vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    width: '70vw',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorInput: {
    '& input': {
      color: '#536E7A',
    },
  },
}))
