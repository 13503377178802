import styled from 'styled-components'

export const Message = styled.span`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Paginate = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`
