import React from 'react'

import * as S from './styles'

export function EmptyList() {
  return (
    <S.Container>
      <div className="list">
        <S.LoadingContainer>
          <span>Nenhum resultado encontrado.</span>
        </S.LoadingContainer>
      </div>
    </S.Container>
  )
}
