import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'

export function Message({ open, type, text, time, onClose }) {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={time}>
      <Alert variant="filled" severity={type} onClose={onClose}>
        {text}
      </Alert>
    </Snackbar>
  )
}

Message.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  time: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}

Message.defaultProps = {
  open: false,
  type: 'success',
  text: '',
  time: 5000,
}
