import moment from 'moment'
import { format } from 'date-fns'

moment.locale('pt-br')

export const formatValues = (type, value) => {
  const valueString = value.toString()
  if (type == 'cpf') {
    return formataCPF(valueString)
  }
  if (type == 'cnpj') {
    return valueString.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    )
  }
  if (type == 'cep') {
    return formatarCEP(valueString)
  }
  if (type == 'phoneNumber') {
    return phoneMask(valueString)
  }
  if (type == 'real') {
    return parseFloat(valueString).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
  }
  if (type == 'date') {
    return moment(valueString).format('L')
  }
  return valueString
}

export function phoneMask(v) {
  let r = v.replace(/\D/g, '')
  r = r.replace(/^0/, '')

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3')
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else if (v.trim() !== '') {
    r = r.replace(/^(\d*)/, '($1')
  }
  return r
}

export function formatarCEP(str) {
  const re = /^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}/
  if (re.test(str)) {
    return str.replace(re, '$1$2-$3')
  }
  return str
}

export function formataCPF(cpf) {
  // retira os caracteres indesejados...
  const document = cpf.replace(/[^\d]/g, '')

  // realizar a formatação...
  return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCnpj(cnpj) {
  return String(cnpj)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatDocument(document) {
  if (!document) return ''
  return document.length === 11 ? formataCPF(document) : formatCnpj(document)
}

export function removeSpecialCharacters(str = '') {
  return str?.replace(/[^\w ]/g, '')
}

export function convertArrayToString(array = []) {
  return String(`${array}`)
    .replace(/(.*),(.*)/, '$1 e $2')
    .replace(/,/g, ', ')
}

export const formatDateAndTime = (date) =>
  format(new Date(date), 'dd/LL/yyyy HH:mm:ss')

export const formatCurrency = (value) =>
  Number(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })

export const formatStructureType = (local) => {
  return (
    {
      'sem-estrutura': 'Sem estrutura',
      'fibrocimento-madeira': 'Telhado - Fibrocimento (Madeira)',
      'fibrocimento-metalico': 'Telhado - Fibrocimento (Metálico)',
      fibrocimento: 'Telhado - Fibrocimento',
      ceramico: 'Telhado - Cerâmico',
      metalico: 'Telhado - Metálico',
      laje: 'Telhado - Lajea',
      solo: 'Solo - Solo',
    }[local] || local
  )
}
