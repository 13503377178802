import Axios from 'axios'
import { useMessage } from 'hooks/useMessage'
import { useEffect, useRef, useState } from 'react'
import { faasService } from 'services/faasService'

export const useFetchProvidersSelectFaaS = () => {
  const [providerOptions, setProviderOptions] = useState([])
  const abortApiCallRef = useRef(null)

  const { onErrorMessage } = useMessage()

  useEffect(() => {
    if (abortApiCallRef.current) {
      abortApiCallRef.current.cancel()
    }

    abortApiCallRef.current = Axios.CancelToken.source()

    faasService
      .getProvidersFaas(abortApiCallRef.current.token)
      .then(({ data }) => {
        setProviderOptions(data || [])
      })
      .catch((err) => {
        if (!Axios.isCancel(err)) {
          onErrorMessage('Ocorreu um erro ao tentar listar os fornecedores!')
        }
      })

    return () => {
      abortApiCallRef.current && abortApiCallRef.current.cancel()
    }
  }, [])

  return { providerOptions }
}
