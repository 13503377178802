import React, { useEffect } from 'react'
import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Helmet } from 'react-helmet'
import { CookiesNotification, GoogleAnalytics } from 'components'
import { AuthProvider } from 'context/authContext'
import { IntegratorModalProvider } from 'context/IntegratorModal'
import { MessageProvider } from 'context/messageContext'
import theme from './theme'
import API from './api'
import Routes from './routes'
import globalConfigs from './config/globalConfigs'
import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './assets/scss/index.scss'
import './App.css'

const url = window.location.href

function App() {
  useEffect(() => {
    if (!localStorage.getItem('tokenGestao')) {
      const paginas = url.split('/')

      if (paginas[3] !== 'auth') {
        window.location.href = '/auth/login'
      }
    } else {
      API.get('/auth/me', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.getItem('tokenGestao'),
        },
      })
        .then((res) => {
          if (res.status === 203) {
            localStorage.removeItem('tokenGestao')
            localStorage.removeItem('userId')
            localStorage.removeItem('tokenAssistant')
            window.location.href = '/auth/login'
          }
        })
        .catch((err) => {})
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Helmet>
          <link rel="shortcut icon" href={`/icons/${globalConfigs?.tabIcon}`} />
        </Helmet>
        <MessageProvider>
          <AuthProvider>
            <IntegratorModalProvider>
              <Routes />
            </IntegratorModalProvider>
          </AuthProvider>
        </MessageProvider>
        <GoogleAnalytics />
        <CookiesNotification />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
