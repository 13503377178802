import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core'
import useRouter from 'utils/useRouter'
import { Navigation } from 'components'
import { useAuth } from 'context/authContext'
import { USER_ROLE_ENUM, USERS_EMAILS_WITH_PERMISSION } from 'constants/users'
import navigationConfig from './navigationConfig'
import navigationConfigUser from './navigationConfigUser'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: '60px',
    height: '60px',
    padding: '4px',
    background: 'linear-gradient(to bottom left, #1335C5, #2D62D0)',

    '& > img': {
      width: '100%',
      height: 'auto',
    }
  },
  name: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}))

function NavBar(props) {
  const { user } = useAuth()
  const { openMobile, onMobileClose, className, ...rest } = props

  const session = useSelector(({ reducer }) => reducer.SESSION)
  const dispatch = useDispatch()

  const isSuperAdmin = user?.role_id === USER_ROLE_ENUM.superAdmin
  const isUserWithPermission = USERS_EMAILS_WITH_PERMISSION.includes(user?.email)

  const { history } = useRouter()
  const router = useRouter()
  const classes = useStyles()

  const handleLogout = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('tokenGestao')
    localStorage.removeItem('tokenAssistant')
    dispatch({
      type: 'INITIAL',
    })
    history.push('/auth/login')
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname])

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={session.avatar}
          to="/"
        />
        <Typography className={classes.name} variant="h4">
          {session.first_name} {session.last_name}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {isSuperAdmin || isUserWithPermission ? (
          <>
            {navigationConfig.map((list) => (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages}
                title={list.title}
              />
            ))}
          </>
        ) : (
          <>
            {navigationConfigUser.map((list) => (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages}
                title={list.title}
              />
            ))}
          </>
        )}
      </nav>
    </div>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
            <Button
              style={{ textTransform: 'none' }}
              fullWidth
              variant="outlined"
              onClick={() => handleLogout()}
            >
              Sair
            </Button>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </>
  )
}

export default NavBar
