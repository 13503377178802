import { z } from 'zod'
import { USER_TYPE_ENUM } from '../constants'

export const UserInfo = z
  .object({
    seller: z.number(),
    discount: z.number(),
    block_user: z.string(),
    user_type: z.string(),
    provider_id: z.number().optional(),
  })
  .refine(
    (scheme) => {
      return !(
        scheme.user_type === USER_TYPE_ENUM.DISTRIBUTOR && !scheme?.provider_id
      )
    },
    { message: 'Escolha um fornecedor.', path: ['provider_id'] },
  )
