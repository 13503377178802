import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Wrapper } from './styles'

export function Breadcrumbs({ breadcrumbs = [], ...rest }) {
  const { push } = useHistory()

  function onBack(isLast, path) {
    if (isLast) return
    push(path)
  }

  return (
    <Wrapper data-testid="breadcrumbs-wrapper" {...rest}>
      {breadcrumbs?.map((breadcrumb, index) => {
        const isLast = index + 1 === breadcrumbs.length
        return (
          <Fragment key={breadcrumb.path}>
            <button
              type="button"
              onClick={() => onBack(isLast, breadcrumb.path)}
              data-active={isLast}
            >
              {breadcrumb.label}
            </button>
            {!isLast && <p>{'>'}</p>}
          </Fragment>
        )
      })}
    </Wrapper>
  )
}
