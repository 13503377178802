import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;

  & .list {
    background-color: #ffff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    overflow-x: auto;

    ${(props) =>
      props.withoutBackground &&
      css`
        background-color: transparent;
        box-shadow: none;
      `}
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  height: 200px;
  & > span {
    margin-top: 12px;
    color: grey;
  }
`
