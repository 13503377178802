import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

import API from '../../../../api';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row[0]}</TableCell>
        <TableCell align="left">{row[1]}</TableCell>
        <TableCell align="left">{row[2]}</TableCell>
        <TableCell align="left">{row[3]}</TableCell>
        <TableCell align="left">{row[4]}</TableCell>
        <TableCell align="left">{row[5]}</TableCell>
        <TableCell align="left">{row[6]}</TableCell>
        <TableCell align="left">{row[7]} </TableCell>
        <TableCell align="left">{row[8]} </TableCell>
        <TableCell align="left">{row[9]} </TableCell>
        <TableCell align="left">{row[10]} </TableCell>
        <TableCell align="left">{row[11]} </TableCell>
        <TableCell align="left">{row[12]} </TableCell>
        <TableCell align="left">{row[13]} </TableCell>
        <TableCell align="left">{row[14]} </TableCell>
        <TableCell align="left">{row[15]} </TableCell>
        <TableCell align="left">{row[16]} </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={18}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Evolução {row[0]}
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Paper elevation={3} style={{height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px'}}>
                  <Bar width="1200" data={
                    {
                      labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                      datasets: [{
                          label: row[0],
                          barThickness: 80,
                          data: [row[17], row[18], row[19], row[20], row[21], row[22], row[23], row[24], row[25], row[26], row[27], row[28]],
                          backgroundColor: [
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                            'rgba(0, 77, 128, 1)',
                          ]}, {
                          label: row[0],
                          type: 'line',
                          data: [row[17], row[18], row[19], row[20], row[21], row[22], row[23], row[24], row[25], row[26], row[27], row[28]],
                          borderColor: 'rgba(97, 216, 54)'
                        },
                      ],
                    }


                  } options={
                    {
                      legend: {
                        display: false
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                      tooltips: {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        caretSize: 10,
                        yPadding: 20,
                        xPadding: 20,
                        borderWidth: 1,
                        borderColor: theme.palette.divider,
                        backgroundColor: theme.palette.white,
                        titleFontColor: theme.palette.text.primary,
                        bodyFontColor: theme.palette.text.secondary,
                        footerFontColor: theme.palette.text.secondary,
                        callbacks: {
                          legend: () => {},
                          title: () => {},
                          label: tooltipItem => {
                            let label = parseFloat(tooltipItem.yLabel).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                  
                            return label;
                          }
                        }
                      }
                    }
                  } />
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

 const KpiGeral = props => {

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => { 

    getKpiGeral();

  }, [props.ano])

  const getKpiGeral = () => {

    setOpenBackdrop(true);

    API.get('/gestao/dashboard?segmento=kpis_geral&ano=' + props.ano, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'token': localStorage.getItem('tokenGestao')
      }
    })
    .then(res => {
  
      let linhas = [];

      let now = new Date;
      let mesAtual = now.getMonth() + 1;

      let evolucaoAnualMrr = 0;
      let evolucaoAnualFaturamento = 0;
      let evolucaoAnualClientes_ativos = 0;
      let evolucaoAnualEmpresas_ativas = 0;
      let evolucaoAnualLeads = 0;
      let evolucaoAnualEmpresasNovas = 0;
      let evolucaoAnualChurn = 0;
      let evolucaoAnualTicket_medio_empresa = 0;
      let evolucaoAnualCac = 0;
      let evolucaoAnualCpc = 0;
      let evolucaoAnualCliques = 0;
      let evolucaoAnualPayback = 0;
      let evolucaoAnualCalls_marcadas = 0;
      let evolucaoAnualCalls_efetivadas = 0;
      let evolucaoAnualConversao_empresas = 0;
      let evolucaoAnualConversao_usuarios = 0;
      let evolucaoAnualConversao_usuarios_calls_efetivados = 0;
      let evolucaoAnualConversao_empresas_calls_marcadas = 0;
      let evolucaoAnualConversao_empresas_calls_efetivadas = 0;
      let evolucaoAnualConversao_usuarios_leads = 0;
      let evolucaoAnualConversao_empresas_leads = 0;
      let evolucaoAnualPropostas_empresas = 0;
      let evolucaoAnualDimensionamentos_empresas = 0;
      let evolucaoAnualCotacao_empresas = 0;
      let evolucaoAnualVolume_kwp = 0;
      let evolucaoAnualVolume_propostas = 0;
      let evolucaoAnualKwp_medio_proposta = 0;
      let evolucaoAnualvalor_medio_proposta = 0;
      let evolucaoAnualgmv = 0;
      let evolucaoAnualTake_rate_rs = 0;
      let evolucaoAnualTake_rate_porcentagem = 0;
      let evolucaoAnualVolume_vendas = 0;
      let evolucaoAnualTicket_medio_equipamentos = 0;
      let evolucaoAnualConversao_usuarios_calls_marcadas = 0;
      let evolucaoAnualVolume_rs = 0;

      if(res.data.mrr[1] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[2]/res.data.mrr[1])-1);
      }
      if(res.data.mrr[2] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[3]/res.data.mrr[2])-1);
      }
      if(res.data.mrr[3] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[4]/res.data.mrr[3])-1);
      }
      if(res.data.mrr[4] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[5]/res.data.mrr[4])-1);
      }
      if(res.data.mrr[5] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[6]/res.data.mrr[5])-1);
      }
      if(res.data.mrr[6] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[7]/res.data.mrr[6])-1);
      }
      if(res.data.mrr[7] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[8]/res.data.mrr[7])-1);
      }
      if(res.data.mrr[8] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[9]/res.data.mrr[8])-1);
      }
      if(res.data.mrr[9] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[10]/res.data.mrr[9])-1);
      }
      if(res.data.mrr[10] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[11]/res.data.mrr[10])-1);
      }
      if(res.data.mrr[11] > 0){
        evolucaoAnualMrr = evolucaoAnualMrr + ((res.data.mrr[12]/res.data.mrr[11])-1);
      }

      if(res.data.faturamento[1] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[2]/res.data.faturamento[1])-1);
      }
      if(res.data.faturamento[2] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[3]/res.data.faturamento[2])-1);
      }
      if(res.data.faturamento[3] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[4]/res.data.faturamento[3])-1);
      }
      if(res.data.faturamento[4] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[5]/res.data.faturamento[4])-1);
      }
      if(res.data.faturamento[5] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[6]/res.data.faturamento[5])-1);
      }
      if(res.data.faturamento[6] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[7]/res.data.faturamento[6])-1);
      }
      if(res.data.faturamento[7] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[8]/res.data.faturamento[7])-1);
      }
      if(res.data.faturamento[8] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[9]/res.data.faturamento[8])-1);
      }
      if(res.data.faturamento[9] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[10]/res.data.faturamento[9])-1);
      }
      if(res.data.faturamento[10] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[11]/res.data.faturamento[10])-1);
      }
      if(res.data.faturamento[11] > 0){
        evolucaoAnualFaturamento = evolucaoAnualFaturamento + ((res.data.faturamento[12]/res.data.faturamento[11])-1);
      }

      if(res.data.clientes_ativos[1] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[2]/res.data.clientes_ativos[1])-1);
      }
      if(res.data.clientes_ativos[2] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[3]/res.data.clientes_ativos[2])-1);
      }
      if(res.data.clientes_ativos[3] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[4]/res.data.clientes_ativos[3])-1);
      }
      if(res.data.clientes_ativos[4] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[5]/res.data.clientes_ativos[4])-1);
      }
      if(res.data.clientes_ativos[5] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[6]/res.data.clientes_ativos[5])-1);
      }
      if(res.data.clientes_ativos[6] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[7]/res.data.clientes_ativos[6])-1);
      }
      if(res.data.clientes_ativos[7] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[8]/res.data.clientes_ativos[7])-1);
      }
      if(res.data.clientes_ativos[8] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[9]/res.data.clientes_ativos[8])-1);
      }
      if(res.data.clientes_ativos[9] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[10]/res.data.clientes_ativos[9])-1);
      }
      if(res.data.clientes_ativos[10] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[11]/res.data.clientes_ativos[10])-1);
      }
      if(res.data.clientes_ativos[11] > 0){
        evolucaoAnualClientes_ativos = evolucaoAnualClientes_ativos + ((res.data.clientes_ativos[12]/res.data.clientes_ativos[11])-1);
      }

      if(res.data.empresas_ativas[1] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[2]/res.data.empresas_ativas[1])-1);
      }
      if(res.data.empresas_ativas[2] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[3]/res.data.empresas_ativas[2])-1);
      }
      if(res.data.empresas_ativas[3] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[4]/res.data.empresas_ativas[3])-1);
      }
      if(res.data.empresas_ativas[4] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[5]/res.data.empresas_ativas[4])-1);
      }
      if(res.data.empresas_ativas[5] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[6]/res.data.empresas_ativas[5])-1);
      }
      if(res.data.empresas_ativas[6] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[7]/res.data.empresas_ativas[6])-1);
      }
      if(res.data.empresas_ativas[7] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[8]/res.data.empresas_ativas[7])-1);
      }
      if(res.data.empresas_ativas[8] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[9]/res.data.empresas_ativas[8])-1);
      }
      if(res.data.empresas_ativas[9] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[10]/res.data.empresas_ativas[9])-1);
      }
      if(res.data.empresas_ativas[10] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[11]/res.data.empresas_ativas[10])-1);
      }
      if(res.data.empresas_ativas[11] > 0){
        evolucaoAnualEmpresas_ativas = evolucaoAnualEmpresas_ativas + ((res.data.empresas_ativas[12]/res.data.empresas_ativas[11])-1);
      }

      //mrr

      linhas.push([
        'MRR',
        parseFloat((res.data.mrr[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.mrr[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        mesAtual === 1 ? 
          (
            res.data.mrr['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.mrr[mesAtual]/res.data.mrr['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.mrr[mesAtual]/res.data.mrr[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualMrr)*100).toFixed(2) + '%',
        parseFloat(res.data.mrr['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        ((100*parseFloat(res.data.mrr[mesAtual]))/parseFloat(res.data.mrr['meta_ano'])).toFixed(2) + '%',
        res.data.mrr[1],
        res.data.mrr[2],
        res.data.mrr[3],
        res.data.mrr[4],
        res.data.mrr[5],
        res.data.mrr[6],
        res.data.mrr[7],
        res.data.mrr[8],
        res.data.mrr[9],
        res.data.mrr[10],
        res.data.mrr[11],
        res.data.mrr[12]
      ]);

      //faturamento
      linhas.push([
        'Faturamento',
        parseFloat((res.data.faturamento[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        parseFloat((res.data.faturamento[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        mesAtual === 1 ? 
          (
            res.data.faturamento['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.faturamento[mesAtual]/res.data.faturamento['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.faturamento[mesAtual]/res.data.faturamento[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualFaturamento)*100).toFixed(2) + '%',
        parseFloat(res.data.faturamento['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        ((100*parseFloat(res.data.faturamento[mesAtual]))/parseFloat(res.data.faturamento['meta_ano'])).toFixed(2) + '%',
        res.data.faturamento[1],
        res.data.faturamento[2],
        res.data.faturamento[3],
        res.data.faturamento[4],
        res.data.faturamento[5],
        res.data.faturamento[6],
        res.data.faturamento[7],
        res.data.faturamento[8],
        res.data.faturamento[9],
        res.data.faturamento[10],
        res.data.faturamento[11],
        res.data.faturamento[12]
      ]);

      //clientes ativos

      linhas.push([
        'Clientes ativos',
        parseFloat((res.data.clientes_ativos[1]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[2]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[3]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[4]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[5]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[6]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[7]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[8]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[9]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[10]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[11]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.clientes_ativos[12]).toFixed(2)).toLocaleString('pt-br'),
        mesAtual === 1 ? 
          (
            res.data.clientes_ativos['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.clientes_ativos[mesAtual]/res.data.clientes_ativos['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.clientes_ativos[mesAtual]/res.data.clientes_ativos[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualClientes_ativos)*100).toFixed(2) + '%',
        res.data.clientes_ativos['meta_ano'],
        ((100*parseFloat(res.data.clientes_ativos[mesAtual]))/parseFloat(res.data.clientes_ativos['meta_ano'])).toFixed(2) + '%' ,
        res.data.clientes_ativos[1],
        res.data.clientes_ativos[2],
        res.data.clientes_ativos[3],
        res.data.clientes_ativos[4],
        res.data.clientes_ativos[5],
        res.data.clientes_ativos[6],
        res.data.clientes_ativos[7],
        res.data.clientes_ativos[8],
        res.data.clientes_ativos[9],
        res.data.clientes_ativos[10],
        res.data.clientes_ativos[11],
        res.data.clientes_ativos[12]
      ]);

      //Empresas ativas

      linhas.push([
        'Empresas ativas',
        parseFloat((res.data.empresas_ativas[1]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[2]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[3]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[4]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[5]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[6]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[7]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[8]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[9]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[10]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[11]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_ativas[12]).toFixed(2)).toLocaleString('pt-br'),
        mesAtual === 1 ? 
          (
            res.data.empresas_ativas['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.empresas_ativas[mesAtual]/res.data.empresas_ativas['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.empresas_ativas[mesAtual]/res.data.empresas_ativas[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualEmpresas_ativas)*100).toFixed(2) + '%',
        res.data.empresas_ativas['meta_ano'],
        ((100*parseFloat(res.data.empresas_ativas[mesAtual]))/parseFloat(res.data.empresas_ativas['meta_ano'])).toFixed(2) + '%' ,
        res.data.empresas_ativas[1],
        res.data.empresas_ativas[2],
        res.data.empresas_ativas[3],
        res.data.empresas_ativas[4],
        res.data.empresas_ativas[5],
        res.data.empresas_ativas[6],
        res.data.empresas_ativas[7],
        res.data.empresas_ativas[8],
        res.data.empresas_ativas[9],
        res.data.empresas_ativas[10],
        res.data.empresas_ativas[11],
        res.data.empresas_ativas[12]
      ]);

      //Leads

      /*linhas.push([
        'Leads',
        parseFloat((res.data.leads[1]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[2]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[3]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[4]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[5]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[6]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[7]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[8]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[9]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[10]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[11]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[12]).toFixed(2)).toLocaleString('pt-br'),
        mesAtual === 1 ? 
          (
            res.data.leads['dezembro_ano_anterior'] > 0 ?
              ((res.data.leads[mesAtual]/res.data.leads['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
            : 
              'Não se aplica'
          )
        : 
          ((res.data.leads[mesAtual]/res.data.leads[mesAtual-1])-1).toFixed(2)*100 + '%'
        ,
        (evolucaoAnualLeads).toFixed(2)*100 + '%',
        parseFloat(res.data.leads['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        ((100*parseFloat(res.data.leads[mesAtual]))/parseFloat(res.data.leads['meta_ano'])).toFixed(2) + '%' ,
        res.data.leads[1],
        res.data.leads[2],
        res.data.leads[3],
        res.data.leads[4],
        res.data.leads[5],
        res.data.leads[6],
        res.data.leads[7],
        res.data.leads[8],
        res.data.leads[9],
        res.data.leads[10],
        res.data.leads[11],
        res.data.leads[12]
      ]);*/

      linhas.push([
        'Leads',
        parseFloat((res.data.empresas_novas[1]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[2]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[3]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[4]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[5]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[6]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[7]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[8]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[9]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[10]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[11]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.empresas_novas[12]).toFixed(2)).toLocaleString('pt-br'),
        mesAtual === 1 ? 
          (
            res.data.empresas_novas['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.empresas_novas[mesAtual]/res.data.empresas_novas['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.empresas_novas[mesAtual]/res.data.empresas_novas[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualEmpresasNovas)*100).toFixed(2) + '%',
        res.data.empresas_novas['meta_ano'],
        ((100*parseFloat(res.data.empresas_novas[mesAtual]))/parseFloat(res.data.empresas_novas['meta_ano'])).toFixed(2) + '%' ,
        res.data.empresas_novas[1],
        res.data.empresas_novas[2],
        res.data.empresas_novas[3],
        res.data.empresas_novas[4],
        res.data.empresas_novas[5],
        res.data.empresas_novas[6],
        res.data.empresas_novas[7],
        res.data.empresas_novas[8],
        res.data.empresas_novas[9],
        res.data.empresas_novas[10],
        res.data.empresas_novas[11],
        res.data.empresas_novas[12]
      ]);

      // Novas empresas
      
      linhas.push([
        'Quantidade de vendas',
        parseFloat((res.data.leads[1]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[2]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[3]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[4]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[5]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[6]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[7]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[8]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[9]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[10]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[11]).toFixed(2)).toLocaleString('pt-br'),
        parseFloat((res.data.leads[12]).toFixed(2)).toLocaleString('pt-br'),
        mesAtual === 1 ? 
          (
            res.data.leads['dezembro_ano_anterior'] > 0 ?
              parseFloat(((res.data.leads[mesAtual]/res.data.leads['dezembro_ano_anterior'])-1)*100).toFixed(2) + '%' 
            : 
              'Não se aplica'
          )
        : 
          parseFloat(((res.data.leads[mesAtual]/res.data.leads[mesAtual-1])-1)*100).toFixed(2) + '%'
        ,
        parseFloat((evolucaoAnualLeads)*100).toFixed(2) + '%',
        res.data.leads['meta_ano'],
        ((100*parseFloat(res.data.leads[mesAtual]))/parseFloat(res.data.leads['meta_ano'])).toFixed(2) + '%' ,
        res.data.leads[1],
        res.data.leads[2],
        res.data.leads[3],
        res.data.leads[4],
        res.data.leads[5],
        res.data.leads[6],
        res.data.leads[7],
        res.data.leads[8],
        res.data.leads[9],
        res.data.leads[10],
        res.data.leads[11],
        res.data.leads[12]
      ]);

    /*//Churn

    linhas.push([
      'Churn',
      parseFloat((res.data.churn[1]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[2]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[3]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[4]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[5]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[6]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[7]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[8]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[9]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[10]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[11]).toFixed(2)*100 + '%'),
      parseFloat((res.data.churn[12]).toFixed(2)*100 + '%'),
      mesAtual === 1 ? 
        (
          res.data.churn['dezembro_ano_anterior'] > 0 ?
            ((res.data.churn[mesAtual]/res.data.churn['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.churn[mesAtual]/res.data.churn[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualChurn).toFixed(2)*100 + '%',
      parseFloat(res.data.churn['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.churn[mesAtual]))/parseFloat(res.data.churn['meta_ano'])).toFixed(2) + '%' ,
      res.data.churn[1],
      res.data.churn[2],
      res.data.churn[3],
      res.data.churn[4],
      res.data.churn[5],
      res.data.churn[6],
      res.data.churn[7],
      res.data.churn[8],
      res.data.churn[9],
      res.data.churn[10],
      res.data.churn[11],
      res.data.churn[12]
    ]);

    //ticket_medio_empresa

    linhas.push([
      'Ticket médio / empresa',
      parseFloat((res.data.ticket_medio_empresa[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_empresa[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.ticket_medio_empresa['dezembro_ano_anterior'] > 0 ?
            ((res.data.ticket_medio_empresa[mesAtual]/res.data.ticket_medio_empresa['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.ticket_medio_empresa[mesAtual]/res.data.ticket_medio_empresa[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualTicket_medio_empresa).toFixed(2)*100 + '%',
      parseFloat(res.data.ticket_medio_empresa['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.ticket_medio_empresa[mesAtual]))/parseFloat(res.data.ticket_medio_empresa['meta_ano'])).toFixed(2) + '%' ,
      res.data.ticket_medio_empresa[1],
      res.data.ticket_medio_empresa[2],
      res.data.ticket_medio_empresa[3],
      res.data.ticket_medio_empresa[4],
      res.data.ticket_medio_empresa[5],
      res.data.ticket_medio_empresa[6],
      res.data.ticket_medio_empresa[7],
      res.data.ticket_medio_empresa[8],
      res.data.ticket_medio_empresa[9],
      res.data.ticket_medio_empresa[10],
      res.data.ticket_medio_empresa[11],
      res.data.ticket_medio_empresa[12]
    ]);

    //ticket_medio_usuario

    linhas.push([
      'Ticket médio / usuário',
      parseFloat((res.data.ticket_medio_usuario[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_usuario[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.ticket_medio_usuario['dezembro_ano_anterior'] > 0 ?
            ((res.data.ticket_medio_usuario[mesAtual]/res.data.ticket_medio_usuario['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.ticket_medio_usuario[mesAtual]/res.data.ticket_medio_usuario[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualTicket_medio_empresa).toFixed(2)*100 + '%',
      parseFloat(res.data.ticket_medio_usuario['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.ticket_medio_usuario[mesAtual]))/parseFloat(res.data.ticket_medio_usuario['meta_ano'])).toFixed(2) + '%' ,
      res.data.ticket_medio_usuario[1],
      res.data.ticket_medio_usuario[2],
      res.data.ticket_medio_usuario[3],
      res.data.ticket_medio_usuario[4],
      res.data.ticket_medio_usuario[5],
      res.data.ticket_medio_usuario[6],
      res.data.ticket_medio_usuario[7],
      res.data.ticket_medio_usuario[8],
      res.data.ticket_medio_usuario[9],
      res.data.ticket_medio_usuario[10],
      res.data.ticket_medio_usuario[11],
      res.data.ticket_medio_usuario[12]
    ]);

    //cac

    linhas.push([
      'CAC',
      parseFloat((res.data.cac[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cac[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.cac['dezembro_ano_anterior'] > 0 ?
            ((res.data.cac[mesAtual]/res.data.cac['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.cac[mesAtual]/res.data.cac[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCac).toFixed(2)*100 + '%',
      parseFloat(res.data.cac['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.cac[mesAtual]))/parseFloat(res.data.cac['meta_ano'])).toFixed(2) + '%' ,
      res.data.cac[1],
      res.data.cac[2],
      res.data.cac[3],
      res.data.cac[4],
      res.data.cac[5],
      res.data.cac[6],
      res.data.cac[7],
      res.data.cac[8],
      res.data.cac[9],
      res.data.cac[10],
      res.data.cac[11],
      res.data.cac[12]
    ]);

    //cpc
    linhas.push([
      'CPC',
      parseFloat((res.data.cpc[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.cpc[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.cpc['dezembro_ano_anterior'] > 0 ?
            ((res.data.cpc[mesAtual]/res.data.cpc['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.cpc[mesAtual]/res.data.cpc[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCpc).toFixed(2)*100 + '%',
      parseFloat(res.data.cpc['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.cpc[mesAtual]))/parseFloat(res.data.cpc['meta_ano'])).toFixed(2) + '%' ,
      res.data.cpc[1],
      res.data.cpc[2],
      res.data.cpc[3],
      res.data.cpc[4],
      res.data.cpc[5],
      res.data.cpc[6],
      res.data.cpc[7],
      res.data.cpc[8],
      res.data.cpc[9],
      res.data.cpc[10],
      res.data.cpc[11],
      res.data.cpc[12]
    ]);

    //Cliques

    linhas.push([
      'Cliques',
      parseFloat((res.data.cliques[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cliques[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.cliques['dezembro_ano_anterior'] > 0 ?
            ((res.data.cliques[mesAtual]/res.data.cliques['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.cliques[mesAtual]/res.data.cliques[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCliques).toFixed(2)*100 + '%',
      parseFloat(res.data.cliques['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.cliques[mesAtual]))/parseFloat(res.data.cliques['meta_ano'])).toFixed(2) + '%' ,
      res.data.cliques[1],
      res.data.cliques[2],
      res.data.cliques[3],
      res.data.cliques[4],
      res.data.cliques[5],
      res.data.cliques[6],
      res.data.cliques[7],
      res.data.cliques[8],
      res.data.cliques[9],
      res.data.cliques[10],
      res.data.cliques[11],
      res.data.cliques[12]
    ]);

    //payback
    linhas.push([
      'Payback',
      parseFloat((res.data.payback[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.payback[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.payback['dezembro_ano_anterior'] > 0 ?
            ((res.data.payback[mesAtual]/res.data.payback['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.payback[mesAtual]/res.data.payback[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualPayback).toFixed(2)*100 + '%',
      parseFloat(res.data.payback['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.payback[mesAtual]))/parseFloat(res.data.payback['meta_ano'])).toFixed(2) + '%' ,
      res.data.payback[1],
      res.data.payback[2],
      res.data.payback[3],
      res.data.payback[4],
      res.data.payback[5],
      res.data.payback[6],
      res.data.payback[7],
      res.data.payback[8],
      res.data.payback[9],
      res.data.payback[10],
      res.data.payback[11],
      res.data.payback[12]
    ]);

    //calls_marcadas

    linhas.push([
      'Calls marcadas',
      parseFloat((res.data.calls_marcadas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_marcadas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.calls_marcadas['dezembro_ano_anterior'] > 0 ?
            ((res.data.calls_marcadas[mesAtual]/res.data.calls_marcadas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.calls_marcadas[mesAtual]/res.data.calls_marcadas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCalls_marcadas).toFixed(2)*100 + '%',
      parseFloat(res.data.calls_marcadas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.calls_marcadas[mesAtual]))/parseFloat(res.data.calls_marcadas['meta_ano'])).toFixed(2) + '%' ,
      res.data.calls_marcadas[1],
      res.data.calls_marcadas[2],
      res.data.calls_marcadas[3],
      res.data.calls_marcadas[4],
      res.data.calls_marcadas[5],
      res.data.calls_marcadas[6],
      res.data.calls_marcadas[7],
      res.data.calls_marcadas[8],
      res.data.calls_marcadas[9],
      res.data.calls_marcadas[10],
      res.data.calls_marcadas[11],
      res.data.calls_marcadas[12]
    ]);

    //calls_efetivadas

    linhas.push([
      'Calls efetivadas',
      parseFloat((res.data.calls_efetivadas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.calls_efetivadas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.calls_efetivadas['dezembro_ano_anterior'] > 0 ?
            ((res.data.calls_efetivadas[mesAtual]/res.data.calls_efetivadas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.calls_efetivadas[mesAtual]/res.data.calls_efetivadas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCalls_efetivadas).toFixed(2)*100 + '%',
      parseFloat(res.data.calls_efetivadas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.calls_efetivadas[mesAtual]))/parseFloat(res.data.calls_efetivadas['meta_ano'])).toFixed(2) + '%' ,
      res.data.calls_efetivadas[1],
      res.data.calls_efetivadas[2],
      res.data.calls_efetivadas[3],
      res.data.calls_efetivadas[4],
      res.data.calls_efetivadas[5],
      res.data.calls_efetivadas[6],
      res.data.calls_efetivadas[7],
      res.data.calls_efetivadas[8],
      res.data.calls_efetivadas[9],
      res.data.calls_efetivadas[10],
      res.data.calls_efetivadas[11],
      res.data.calls_efetivadas[12]
    ]);

    //conversao_empresas

    linhas.push([
      'Conversão de empresas',
      parseFloat((res.data.conversao_empresas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_empresas['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_empresas[mesAtual]/res.data.conversao_empresas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_empresas[mesAtual]/res.data.conversao_empresas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_empresas).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_empresas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_empresas[mesAtual]))/parseFloat(res.data.conversao_empresas['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_empresas[1],
      res.data.conversao_empresas[2],
      res.data.conversao_empresas[3],
      res.data.conversao_empresas[4],
      res.data.conversao_empresas[5],
      res.data.conversao_empresas[6],
      res.data.conversao_empresas[7],
      res.data.conversao_empresas[8],
      res.data.conversao_empresas[9],
      res.data.conversao_empresas[10],
      res.data.conversao_empresas[11],
      res.data.conversao_empresas[12]
    ]);

    //conversao_usuarios

    linhas.push([
      'Conversão de usuários',
      parseFloat((res.data.conversao_usuarios[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_usuarios['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_usuarios[mesAtual]/res.data.conversao_usuarios['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_usuarios[mesAtual]/res.data.conversao_usuarios[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_usuarios).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_usuarios['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_usuarios[mesAtual]))/parseFloat(res.data.conversao_usuarios['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_usuarios[1],
      res.data.conversao_usuarios[2],
      res.data.conversao_usuarios[3],
      res.data.conversao_usuarios[4],
      res.data.conversao_usuarios[5],
      res.data.conversao_usuarios[6],
      res.data.conversao_usuarios[7],
      res.data.conversao_usuarios[8],
      res.data.conversao_usuarios[9],
      res.data.conversao_usuarios[10],
      res.data.conversao_usuarios[11],
      res.data.conversao_usuarios[12]
    ]);

    //conversao_usuarios_calls_efetivados

    linhas.push([
      'Conversão de usuários / calls efetivados',
      parseFloat((res.data.conversao_usuarios_calls_efetivados[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_efetivados[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_usuarios_calls_efetivados['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_usuarios_calls_efetivados[mesAtual]/res.data.conversao_usuarios_calls_efetivados['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_usuarios_calls_efetivados[mesAtual]/res.data.conversao_usuarios_calls_efetivados[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_usuarios_calls_efetivados).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_usuarios_calls_efetivados['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_usuarios_calls_efetivados[mesAtual]))/parseFloat(res.data.conversao_usuarios_calls_efetivados['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_usuarios_calls_efetivados[1],
      res.data.conversao_usuarios_calls_efetivados[2],
      res.data.conversao_usuarios_calls_efetivados[3],
      res.data.conversao_usuarios_calls_efetivados[4],
      res.data.conversao_usuarios_calls_efetivados[5],
      res.data.conversao_usuarios_calls_efetivados[6],
      res.data.conversao_usuarios_calls_efetivados[7],
      res.data.conversao_usuarios_calls_efetivados[8],
      res.data.conversao_usuarios_calls_efetivados[9],
      res.data.conversao_usuarios_calls_efetivados[10],
      res.data.conversao_usuarios_calls_efetivados[11],
      res.data.conversao_usuarios_calls_efetivados[12]
    ]);

    //conversao_usuarios_calls_marcadas

    linhas.push([
      'Conversão de usuários / calls marcadas',
      parseFloat((res.data.conversao_usuarios_calls_marcadas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_calls_marcadas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_usuarios_calls_marcadas['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_usuarios_calls_marcadas[mesAtual]/res.data.conversao_usuarios_calls_marcadas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_usuarios_calls_marcadas[mesAtual]/res.data.conversao_usuarios_calls_marcadas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_usuarios_calls_marcadas).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_usuarios_calls_marcadas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_usuarios_calls_marcadas[mesAtual]))/parseFloat(res.data.conversao_usuarios_calls_marcadas['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_usuarios_calls_marcadas[1],
      res.data.conversao_usuarios_calls_marcadas[2],
      res.data.conversao_usuarios_calls_marcadas[3],
      res.data.conversao_usuarios_calls_marcadas[4],
      res.data.conversao_usuarios_calls_marcadas[5],
      res.data.conversao_usuarios_calls_marcadas[6],
      res.data.conversao_usuarios_calls_marcadas[7],
      res.data.conversao_usuarios_calls_marcadas[8],
      res.data.conversao_usuarios_calls_marcadas[9],
      res.data.conversao_usuarios_calls_marcadas[10],
      res.data.conversao_usuarios_calls_marcadas[11],
      res.data.conversao_usuarios_calls_marcadas[12]
    ]);

    //conversao_empresas_calls_marcadas

    linhas.push([
      'Conversão de empresas / calls marcados',
      parseFloat((res.data.conversao_empresas_calls_marcadas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_marcadas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_empresas_calls_marcadas['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_empresas_calls_marcadas[mesAtual]/res.data.conversao_empresas_calls_marcadas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_empresas_calls_marcadas[mesAtual]/res.data.conversao_empresas_calls_marcadas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_empresas_calls_marcadas).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_empresas_calls_marcadas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_empresas_calls_marcadas[mesAtual]))/parseFloat(res.data.conversao_empresas_calls_marcadas['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_empresas_calls_marcadas[1],
      res.data.conversao_empresas_calls_marcadas[2],
      res.data.conversao_empresas_calls_marcadas[3],
      res.data.conversao_empresas_calls_marcadas[4],
      res.data.conversao_empresas_calls_marcadas[5],
      res.data.conversao_empresas_calls_marcadas[6],
      res.data.conversao_empresas_calls_marcadas[7],
      res.data.conversao_empresas_calls_marcadas[8],
      res.data.conversao_empresas_calls_marcadas[9],
      res.data.conversao_empresas_calls_marcadas[10],
      res.data.conversao_empresas_calls_marcadas[11],
      res.data.conversao_empresas_calls_marcadas[12]
    ]);

    //conversao_empresas_calls_efetivadas

    linhas.push([
      'Conversão de empresas / calls efetivados',
      parseFloat((res.data.conversao_empresas_calls_efetivadas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_calls_efetivadas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_empresas_calls_efetivadas['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_empresas_calls_efetivadas[mesAtual]/res.data.conversao_empresas_calls_efetivadas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_empresas_calls_efetivadas[mesAtual]/res.data.conversao_empresas_calls_efetivadas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_empresas_calls_efetivadas).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_empresas_calls_efetivadas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_empresas_calls_efetivadas[mesAtual]))/parseFloat(res.data.conversao_empresas_calls_efetivadas['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_empresas_calls_efetivadas[1],
      res.data.conversao_empresas_calls_efetivadas[2],
      res.data.conversao_empresas_calls_efetivadas[3],
      res.data.conversao_empresas_calls_efetivadas[4],
      res.data.conversao_empresas_calls_efetivadas[5],
      res.data.conversao_empresas_calls_efetivadas[6],
      res.data.conversao_empresas_calls_efetivadas[7],
      res.data.conversao_empresas_calls_efetivadas[8],
      res.data.conversao_empresas_calls_efetivadas[9],
      res.data.conversao_empresas_calls_efetivadas[10],
      res.data.conversao_empresas_calls_efetivadas[11],
      res.data.conversao_empresas_calls_efetivadas[12]
    ]);

    //conversao_usuarios_leads

    linhas.push([
      'Conversão de usuários / leads',
      parseFloat((res.data.conversao_usuarios_leads[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_usuarios_leads[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_usuarios_leads['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_usuarios_leads[mesAtual]/res.data.conversao_usuarios_leads['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_usuarios_leads[mesAtual]/res.data.conversao_usuarios_leads[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_usuarios_leads).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_usuarios_leads['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_usuarios_leads[mesAtual]))/parseFloat(res.data.conversao_usuarios_leads['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_usuarios_leads[1],
      res.data.conversao_usuarios_leads[2],
      res.data.conversao_usuarios_leads[3],
      res.data.conversao_usuarios_leads[4],
      res.data.conversao_usuarios_leads[5],
      res.data.conversao_usuarios_leads[6],
      res.data.conversao_usuarios_leads[7],
      res.data.conversao_usuarios_leads[8],
      res.data.conversao_usuarios_leads[9],
      res.data.conversao_usuarios_leads[10],
      res.data.conversao_usuarios_leads[11],
      res.data.conversao_usuarios_leads[12]
    ]);

    //conversao_empresas_leads

    linhas.push([
      'Conversão de empresas / leads',
      parseFloat((res.data.conversao_empresas_leads[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.conversao_empresas_leads[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.conversao_empresas_leads['dezembro_ano_anterior'] > 0 ?
            ((res.data.conversao_empresas_leads[mesAtual]/res.data.conversao_empresas_leads['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.conversao_empresas_leads[mesAtual]/res.data.conversao_empresas_leads[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualConversao_empresas_leads).toFixed(2)*100 + '%',
      parseFloat(res.data.conversao_empresas_leads['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.conversao_empresas_leads[mesAtual]))/parseFloat(res.data.conversao_empresas_leads['meta_ano'])).toFixed(2) + '%' ,
      res.data.conversao_empresas_leads[1],
      res.data.conversao_empresas_leads[2],
      res.data.conversao_empresas_leads[3],
      res.data.conversao_empresas_leads[4],
      res.data.conversao_empresas_leads[5],
      res.data.conversao_empresas_leads[6],
      res.data.conversao_empresas_leads[7],
      res.data.conversao_empresas_leads[8],
      res.data.conversao_empresas_leads[9],
      res.data.conversao_empresas_leads[10],
      res.data.conversao_empresas_leads[11],
      res.data.conversao_empresas_leads[12]
    ]);

    //propostas_empresas

    linhas.push([
      'Propostas/empresas',
      parseFloat((res.data.propostas_empresas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.propostas_empresas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.propostas_empresas['dezembro_ano_anterior'] > 0 ?
            ((res.data.propostas_empresas[mesAtual]/res.data.propostas_empresas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.propostas_empresas[mesAtual]/res.data.propostas_empresas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualPropostas_empresas).toFixed(2)*100 + '%',
      parseFloat(res.data.propostas_empresas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.propostas_empresas[mesAtual]))/parseFloat(res.data.propostas_empresas['meta_ano'])).toFixed(2) + '%' ,
      res.data.propostas_empresas[1],
      res.data.propostas_empresas[2],
      res.data.propostas_empresas[3],
      res.data.propostas_empresas[4],
      res.data.propostas_empresas[5],
      res.data.propostas_empresas[6],
      res.data.propostas_empresas[7],
      res.data.propostas_empresas[8],
      res.data.propostas_empresas[9],
      res.data.propostas_empresas[10],
      res.data.propostas_empresas[11],
      res.data.propostas_empresas[12]
    ]);

    //dimensionamentos_empresas

    linhas.push([
      'Dimensionamentos/empresas',
      parseFloat((res.data.dimensionamentos_empresas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.dimensionamentos_empresas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.dimensionamentos_empresas['dezembro_ano_anterior'] > 0 ?
            ((res.data.dimensionamentos_empresas[mesAtual]/res.data.dimensionamentos_empresas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.dimensionamentos_empresas[mesAtual]/res.data.dimensionamentos_empresas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualDimensionamentos_empresas).toFixed(2)*100 + '%',
      parseFloat(res.data.dimensionamentos_empresas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.dimensionamentos_empresas[mesAtual]))/parseFloat(res.data.dimensionamentos_empresas['meta_ano'])).toFixed(2) + '%' ,
      res.data.dimensionamentos_empresas[1],
      res.data.dimensionamentos_empresas[2],
      res.data.dimensionamentos_empresas[3],
      res.data.dimensionamentos_empresas[4],
      res.data.dimensionamentos_empresas[5],
      res.data.dimensionamentos_empresas[6],
      res.data.dimensionamentos_empresas[7],
      res.data.dimensionamentos_empresas[8],
      res.data.dimensionamentos_empresas[9],
      res.data.dimensionamentos_empresas[10],
      res.data.dimensionamentos_empresas[11],
      res.data.dimensionamentos_empresas[12]
    ]);

    //cotacao_empresas

    linhas.push([
      'Cotações/empresas',
      parseFloat((res.data.cotacao_empresas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.cotacao_empresas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.cotacao_empresas['dezembro_ano_anterior'] > 0 ?
            ((res.data.cotacao_empresas[mesAtual]/res.data.cotacao_empresas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.cotacao_empresas[mesAtual]/res.data.cotacao_empresas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualCotacao_empresas).toFixed(2)*100 + '%',
      parseFloat(res.data.cotacao_empresas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.cotacao_empresas[mesAtual]))/parseFloat(res.data.cotacao_empresas['meta_ano'])).toFixed(2) + '%' ,
      res.data.cotacao_empresas[1],
      res.data.cotacao_empresas[2],
      res.data.cotacao_empresas[3],
      res.data.cotacao_empresas[4],
      res.data.cotacao_empresas[5],
      res.data.cotacao_empresas[6],
      res.data.cotacao_empresas[7],
      res.data.cotacao_empresas[8],
      res.data.cotacao_empresas[9],
      res.data.cotacao_empresas[10],
      res.data.cotacao_empresas[11],
      res.data.cotacao_empresas[12]
    ]);

    //volume_kwp

    linhas.push([
      'Volume de propostas em kWp',
      parseFloat((res.data.volume_kwp[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_kwp[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.volume_kwp['dezembro_ano_anterior'] > 0 ?
            ((res.data.volume_kwp[mesAtual]/res.data.volume_kwp['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.volume_kwp[mesAtual]/res.data.volume_kwp[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualVolume_kwp).toFixed(2)*100 + '%',
      parseFloat(res.data.volume_kwp['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.volume_kwp[mesAtual]))/parseFloat(res.data.volume_kwp['meta_ano'])).toFixed(2) + '%' ,
      res.data.volume_kwp[1],
      res.data.volume_kwp[2],
      res.data.volume_kwp[3],
      res.data.volume_kwp[4],
      res.data.volume_kwp[5],
      res.data.volume_kwp[6],
      res.data.volume_kwp[7],
      res.data.volume_kwp[8],
      res.data.volume_kwp[9],
      res.data.volume_kwp[10],
      res.data.volume_kwp[11],
      res.data.volume_kwp[12]
    ]);

    //volume_rs

    linhas.push([
      'Volume de propostas em R$',
      parseFloat((res.data.volume_rs[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.volume_rs[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.volume_rs['dezembro_ano_anterior'] > 0 ?
            ((res.data.volume_rs[mesAtual]/res.data.volume_rs['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.volume_rs[mesAtual]/res.data.volume_rs[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualVolume_rs).toFixed(2)*100 + '%',
      parseFloat(res.data.volume_rs['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.volume_rs[mesAtual]))/parseFloat(res.data.volume_rs['meta_ano'])).toFixed(2) + '%' ,
      res.data.volume_rs[1],
      res.data.volume_rs[2],
      res.data.volume_rs[3],
      res.data.volume_rs[4],
      res.data.volume_rs[5],
      res.data.volume_rs[6],
      res.data.volume_rs[7],
      res.data.volume_rs[8],
      res.data.volume_rs[9],
      res.data.volume_rs[10],
      res.data.volume_rs[11],
      res.data.volume_rs[12]
    ]);
    
    //volume_propostas

    linhas.push([
      'Volume de propostas',
      parseFloat((res.data.volume_propostas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_propostas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.volume_propostas['dezembro_ano_anterior'] > 0 ?
            ((res.data.volume_propostas[mesAtual]/res.data.volume_propostas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.volume_propostas[mesAtual]/res.data.volume_propostas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualVolume_propostas).toFixed(2)*100 + '%',
      parseFloat(res.data.volume_propostas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.volume_propostas[mesAtual]))/parseFloat(res.data.volume_propostas['meta_ano'])).toFixed(2) + '%' ,
      res.data.volume_propostas[1],
      res.data.volume_propostas[2],
      res.data.volume_propostas[3],
      res.data.volume_propostas[4],
      res.data.volume_propostas[5],
      res.data.volume_propostas[6],
      res.data.volume_propostas[7],
      res.data.volume_propostas[8],
      res.data.volume_propostas[9],
      res.data.volume_propostas[10],
      res.data.volume_propostas[11],
      res.data.volume_propostas[12]
    ]);

     //kwp_medio_proposta

     linhas.push([
      'kWp médio das propostas',
      parseFloat((res.data.kwp_medio_proposta[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.kwp_medio_proposta[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.kwp_medio_proposta['dezembro_ano_anterior'] > 0 ?
            ((res.data.kwp_medio_proposta[mesAtual]/res.data.kwp_medio_proposta['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.kwp_medio_proposta[mesAtual]/res.data.kwp_medio_proposta[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualKwp_medio_proposta).toFixed(2)*100 + '%',
      parseFloat(res.data.kwp_medio_proposta['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.kwp_medio_proposta[mesAtual]))/parseFloat(res.data.kwp_medio_proposta['meta_ano'])).toFixed(2) + '%' ,
      res.data.kwp_medio_proposta[1],
      res.data.kwp_medio_proposta[2],
      res.data.kwp_medio_proposta[3],
      res.data.kwp_medio_proposta[4],
      res.data.kwp_medio_proposta[5],
      res.data.kwp_medio_proposta[6],
      res.data.kwp_medio_proposta[7],
      res.data.kwp_medio_proposta[8],
      res.data.kwp_medio_proposta[9],
      res.data.kwp_medio_proposta[10],
      res.data.kwp_medio_proposta[11],
      res.data.kwp_medio_proposta[12]
    ]);
    
    //valor_medio_proposta

    linhas.push([
      'Valor média das propostas',
      parseFloat((res.data.valor_medio_proposta[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.valor_medio_proposta[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.valor_medio_proposta['dezembro_ano_anterior'] > 0 ?
            ((res.data.valor_medio_proposta[mesAtual]/res.data.valor_medio_proposta['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.valor_medio_proposta[mesAtual]/res.data.valor_medio_proposta[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualvalor_medio_proposta).toFixed(2)*100 + '%',
      parseFloat(res.data.valor_medio_proposta['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.valor_medio_proposta[mesAtual]))/parseFloat(res.data.valor_medio_proposta['meta_ano'])).toFixed(2) + '%' ,
      res.data.valor_medio_proposta[1],
      res.data.valor_medio_proposta[2],
      res.data.valor_medio_proposta[3],
      res.data.valor_medio_proposta[4],
      res.data.valor_medio_proposta[5],
      res.data.valor_medio_proposta[6],
      res.data.valor_medio_proposta[7],
      res.data.valor_medio_proposta[8],
      res.data.valor_medio_proposta[9],
      res.data.valor_medio_proposta[10],
      res.data.valor_medio_proposta[11],
      res.data.valor_medio_proposta[12]
    ]);

    //gmv

    linhas.push([
      'GMV',
      parseFloat((res.data.gmv[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.gmv[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.gmv['dezembro_ano_anterior'] > 0 ?
            ((res.data.gmv[mesAtual]/res.data.gmv['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.gmv[mesAtual]/res.data.gmv[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualgmv).toFixed(2)*100 + '%',
      parseFloat(res.data.gmv['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.gmv[mesAtual]))/parseFloat(res.data.gmv['meta_ano'])).toFixed(2) + '%' ,
      res.data.gmv[1],
      res.data.gmv[2],
      res.data.gmv[3],
      res.data.gmv[4],
      res.data.gmv[5],
      res.data.gmv[6],
      res.data.gmv[7],
      res.data.gmv[8],
      res.data.gmv[9],
      res.data.gmv[10],
      res.data.gmv[11],
      res.data.gmv[12]
    ]);

    //take_rate_rs

    linhas.push([
      'Take rate (R$)',
      parseFloat((res.data.take_rate_rs[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.take_rate_rs[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.take_rate_rs['dezembro_ano_anterior'] > 0 ?
            ((res.data.take_rate_rs[mesAtual]/res.data.take_rate_rs['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.take_rate_rs[mesAtual]/res.data.take_rate_rs[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualTake_rate_rs).toFixed(2)*100 + '%',
      parseFloat(res.data.take_rate_rs['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.take_rate_rs[mesAtual]))/parseFloat(res.data.take_rate_rs['meta_ano'])).toFixed(2) + '%' ,
      res.data.take_rate_rs[1],
      res.data.take_rate_rs[2],
      res.data.take_rate_rs[3],
      res.data.take_rate_rs[4],
      res.data.take_rate_rs[5],
      res.data.take_rate_rs[6],
      res.data.take_rate_rs[7],
      res.data.take_rate_rs[8],
      res.data.take_rate_rs[9],
      res.data.take_rate_rs[10],
      res.data.take_rate_rs[11],
      res.data.take_rate_rs[12]
    ]);

    //take_rate_porcentagem

    linhas.push([
      'Take rate (%)',
      parseFloat((res.data.take_rate_porcentagem[1]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[2]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[3]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[4]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[5]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[6]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[7]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[8]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[9]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[10]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[11]).toFixed(2)*100+'%'),
      parseFloat((res.data.take_rate_porcentagem[12]).toFixed(2)*100+'%'),
      mesAtual === 1 ? 
        (
          res.data.take_rate_porcentagem['dezembro_ano_anterior'] > 0 ?
            ((res.data.take_rate_porcentagem[mesAtual]/res.data.take_rate_porcentagem['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.take_rate_porcentagem[mesAtual]/res.data.take_rate_porcentagem[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualTake_rate_porcentagem).toFixed(2)*100 + '%',
      parseFloat(res.data.take_rate_porcentagem['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.take_rate_porcentagem[mesAtual]))/parseFloat(res.data.take_rate_porcentagem['meta_ano'])).toFixed(2) + '%' ,
      res.data.take_rate_porcentagem[1],
      res.data.take_rate_porcentagem[2],
      res.data.take_rate_porcentagem[3],
      res.data.take_rate_porcentagem[4],
      res.data.take_rate_porcentagem[5],
      res.data.take_rate_porcentagem[6],
      res.data.take_rate_porcentagem[7],
      res.data.take_rate_porcentagem[8],
      res.data.take_rate_porcentagem[9],
      res.data.take_rate_porcentagem[10],
      res.data.take_rate_porcentagem[11],
      res.data.take_rate_porcentagem[12]
    ]);


    //volume_vendas

    linhas.push([
      'Volume de vendas',
      parseFloat((res.data.volume_vendas[1]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[2]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[3]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[4]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[5]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[6]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[7]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[8]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[9]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[10]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[11]).toFixed(2)).toLocaleString('pt-br'),
      parseFloat((res.data.volume_vendas[12]).toFixed(2)).toLocaleString('pt-br'),
      mesAtual === 1 ? 
        (
          res.data.volume_vendas['dezembro_ano_anterior'] > 0 ?
            ((res.data.volume_vendas[mesAtual]/res.data.volume_vendas['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.volume_vendas[mesAtual]/res.data.volume_vendas[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualVolume_vendas).toFixed(2)*100 + '%',
      parseFloat(res.data.volume_vendas['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.volume_vendas[mesAtual]))/parseFloat(res.data.volume_vendas['meta_ano'])).toFixed(2) + '%' ,
      res.data.volume_vendas[1],
      res.data.volume_vendas[2],
      res.data.volume_vendas[3],
      res.data.volume_vendas[4],
      res.data.volume_vendas[5],
      res.data.volume_vendas[6],
      res.data.volume_vendas[7],
      res.data.volume_vendas[8],
      res.data.volume_vendas[9],
      res.data.volume_vendas[10],
      res.data.volume_vendas[11],
      res.data.volume_vendas[12]
    ]);

    //ticket_medio_equipamentos

    linhas.push([
      'Ticket médio (equipamentos)',
      parseFloat((res.data.ticket_medio_equipamentos[1]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[2]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[3]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[4]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[5]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[6]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[7]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[8]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[9]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[10]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[11]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      parseFloat((res.data.ticket_medio_equipamentos[12]).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      mesAtual === 1 ? 
        (
          res.data.ticket_medio_equipamentos['dezembro_ano_anterior'] > 0 ?
            ((res.data.ticket_medio_equipamentos[mesAtual]/res.data.ticket_medio_equipamentos['dezembro_ano_anterior'])-1).toFixed(2)*100 + '%' 
          : 
            'Não se aplica'
        )
      : 
        ((res.data.ticket_medio_equipamentos[mesAtual]/res.data.ticket_medio_equipamentos[mesAtual-1])-1).toFixed(2)*100 + '%'
      ,
      (evolucaoAnualTicket_medio_equipamentos).toFixed(2)*100 + '%',
      parseFloat(res.data.ticket_medio_equipamentos['meta_ano']).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      ((100*parseFloat(res.data.ticket_medio_equipamentos[mesAtual]))/parseFloat(res.data.ticket_medio_equipamentos['meta_ano'])).toFixed(2) + '%' ,
      res.data.ticket_medio_equipamentos[1],
      res.data.ticket_medio_equipamentos[2],
      res.data.ticket_medio_equipamentos[3],
      res.data.ticket_medio_equipamentos[4],
      res.data.ticket_medio_equipamentos[5],
      res.data.ticket_medio_equipamentos[6],
      res.data.ticket_medio_equipamentos[7],
      res.data.ticket_medio_equipamentos[8],
      res.data.ticket_medio_equipamentos[9],
      res.data.ticket_medio_equipamentos[10],
      res.data.ticket_medio_equipamentos[11],
      res.data.ticket_medio_equipamentos[12]
    ]);*/

      setRows(linhas);

      setOpenBackdrop(false);
  
    }).catch(err => {})

  } 

  return (
    <TableContainer component={Paper}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">KPIs</TableCell>
            <TableCell align="center">Janeiro</TableCell>
            <TableCell align="center">Fevereiro</TableCell>
            <TableCell align="center">Março</TableCell>
            <TableCell align="center">Abril</TableCell>
            <TableCell align="center">Maio</TableCell>
            <TableCell align="center">Junho</TableCell>
            <TableCell align="center">Julho</TableCell>
            <TableCell align="center">Agosto</TableCell>
            <TableCell align="center">Setembro</TableCell>
            <TableCell align="center">Outubro</TableCell>
            <TableCell align="center">Novembro</TableCell>
            <TableCell align="center">Dezembro</TableCell>
            <TableCell align="center">Evolução mensal</TableCell>
            <TableCell align="center">Evolução anual</TableCell>
            <TableCell align="center">Meta</TableCell>
            <TableCell align="center">% da meta</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default KpiGeral;