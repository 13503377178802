import { useHistory } from 'react-router'
import { useCallback, useMemo } from 'react'

export function useSearchParams() {
  const history = useHistory()

  const searchParams = useMemo(() => {
    return {
      string: history.location.search.toString(),
      object: new URLSearchParams(history.location.search),
    }
  }, [history.location.search])

  const fetchParam = useCallback(
    (name) => {
      return searchParams.object.get(name)
    },
    [searchParams],
  )

  const defineOneParam = useCallback(
    (name, value) => {
      const params = new URLSearchParams(history.location.search)

      if (value) {
        params.set(name, value)
      } else {
        params.delete(name)
      }

      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      })
    },
    [history],
  )

  const defineMultipleParams = useCallback(
    (params = {}) => {
      Object.entries(params).forEach(([name, value]) => {
        defineOneParam(name, value)
      })
    },
    [defineOneParam],
  )

  return {
    fetchParam,
    searchParams,
    defineOneParam,
    defineMultipleParams,
  }
}
