import React from 'react'
import * as Styled from './styles'

export function Paper({ hasPadding = true, canShowBorder = true, ...props }) {
  return (
    <Styled.Paper
      {...props}
      hasPadding={hasPadding}
      canShowBorder={canShowBorder}
    />
  )
}
