export default {
  name: '77Sol',
  slug: '77sol',
  avatar: '/images/logos/logo-77sol-white.png',
  logo: {
    default: '/images/logos/logo-77sol.png',
    white: '/images/logos/logo-77sol-white.png',
  },
  corporateName: '77 Sol Tecnologia SA.',
  address: {
    complete: 'R. Gomes de Carvalho, 1108 - Vila Olímpia, São Paulo - SP',
    road: 'R. Gomes de Carvalho',
    number: '1108',
    complement: '',
    district: 'Vila Olímpia',
    city: 'São Paulo',
    state: 'SP',
    cep: '04547-004',
  },
  tabIcon: '77sol.png',
  infos: {
    storeTitle: 'Loja 77Sol',
  },
  internalOrderStatus: true,
  ploomesFields: true,
  blockDelivered: false,
  showFinancingChipUserPro: true,
  showLabelSelectionUserPro: true,
  components: {
    multiExportProducts: true,
    leadType: true,
    importProducts: !!(
      window.location.hostname.split('.')[0] === 'dev' ||
      window.location.hostname === 'localhost'
    ),
  },
  canShowPloomesMessages: true,
}
