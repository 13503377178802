import React from 'react'
import { IconButton } from '@material-ui/core'
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '@77sol/icons'
import * as S from './styles'

export function Pagination({
  pages,
  onFirstPage,
  onBackPage,
  onNextPage,
  onLastPage,
}) {
  const currentPage = Number(pages?.current_page)

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pages?.last_page

  return (
    <S.Root>
      <button
        type="button"
        className="button"
        onClick={onFirstPage}
        disabled={isFirstPage}
      >
        <span>Primeira</span>
      </button>
      <IconButton
        size="small"
        className="arrow"
        onClick={onBackPage}
        disabled={isFirstPage}
      >
        <ArrowChevronBackIcon width="20" />
      </IconButton>
      <span>
        {pages?.current_page || 0}/{pages?.last_page || 0}
      </span>
      <IconButton
        className="arrow"
        size="small"
        onClick={onNextPage}
        disabled={isLastPage}
      >
        <ArrowChevronForwardIcon width="20" />
      </IconButton>
      <button
        type="button"
        className="button"
        onClick={onLastPage}
        disabled={isLastPage}
      >
        <span>Última</span>
      </button>
    </S.Root>
  )
}
