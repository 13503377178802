import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from '../layouts/Auth'
import ErrorLayout from '../layouts/Error'
import DashboardLayout from '../layouts/Dashboard'
import DashboardDefaultView from '../views/DashboardDefault'
import DashboardAnalyticsView from '../views/DashboardAnalytics'

export const appRoutes = [
  {
    layout: AuthLayout,
    path: '/auth',
    exact: true,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login')),
      },
    ],
  },
  {
    path: '/errors',
    exact: true,
    layout: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        path: '/errors/*',
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    layout: DashboardLayout,
    routes: [
      {
        path: '/plataforma/projetos',
        exact: true,
        component: lazy(() => import('views/ProjectsList')),
      },
      {
        path: '/plataforma/projetos/usuario/:id',
        exact: true,
        component: lazy(() => import('views/ProjectsByUserList')),
      },
      {
        path: '/plataforma/propostas/cliente/:id',
        exact: true,
        component: lazy(() => import('views/ProposalsByClientList')),
      },
      {
        path: '/plataforma/propostas/projeto/:id',
        exact: true,
        component: lazy(() => import('views/ProposalsByProjectList')),
      },
      {
        path: '/plataforma/orcamentos',
        exact: true,
        component: lazy(() => import('views/QuotesList')),
      },
      {
        path: '/plataforma/orcamentos/:id',
        exact: true,
        component: lazy(() => import('views/EditOrcamento')),
      },
      {
        path: '/plataforma/orcamentos/financiamento/:id',
        exact: true,
        component: lazy(() => import('views/QuotesByFinancingList')),
      },
      {
        path: '/plataforma/orcamentos/proposta/:id',
        exact: true,
        component: lazy(() => import('views/QuotesByProposalList')),
      },
      {
        path: '/plataforma/financiamentos',
        exact: true,
        component: lazy(() => import('views/Plataforma/FinancingsList')),
      },
      {
        path: '/plataforma/simulacoes',
        exact: true,
        component: lazy(() => import('views/Plataforma/FinancingSimulations')),
      },
      {
        path: '/faas/financiamentos',
        exact: true,
        component: lazy(() => import('views/FaaS/FinancingsList')),
      },
      {
        path: '/faas/simulacoes',
        exact: true,
        component: lazy(() => import('views/FaaS/FinancingSimulations')),
      },
      {
        path: '/plataforma/financiamentos/configuracoes/:view',
        exact: true,
        component: lazy(() => import('views/BanksList')),
      },
      {
        path: '/plataforma/financiamentos/:id',
        exact: true,
        component: lazy(() => import('views/EditFinancing')),
      },
      {
        path: '/plataforma/financiamentos/simulacao/:id',
        exact: true,
        component: lazy(() => import('views/EditSimulation')),
      },
      {
        path: '/plataforma/financiamentos/solicitante/:id',
        exact: true,
        component: lazy(() => import('views/EditRequester')),
      },
      {
        path: '/plataforma/financiamentos/proposta/:id',
        exact: true,
        component: lazy(() => import('views/FinancingsByProposalList')),
      },
      {
        path: '/plataforma/tarifas',
        exact: true,
        component: lazy(() => import('views/Tariffs')),
      },
      {
        path: '/plataforma/monitoramento',
        exact: true,
        component: lazy(() => import('views/Monitoring')),
      },
      {
        path: '/loja/produtos',
        exact: true,
        component: lazy(() => import('views/ProductsList')),
      },
      {
        path: '/loja/produtos/adicionar',
        exact: true,
        component: lazy(() => import('views/AddProduct')),
      },
      {
        path: '/loja/produtos/adicionar/:id',
        exact: true,
        component: lazy(() => import('views/AddProduct')),
      },
      {
        path: '/loja/produtos/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditProduct')),
      },
      {
        path: '/loja/marcas',
        exact: true,
        component: lazy(() => import('views/MarcasList')),
      },
      {
        path: '/loja/transportadoras',
        exact: true,
        component: lazy(() => import('views/Carriers')),
      },
      {
        path: '/loja/atributos',
        exact: true,
        component: lazy(() => import('views/AtributosList')),
      },
      {
        path: '/loja/atributos/adicionar',
        exact: true,
        component: lazy(() => import('views/AddAtributo')),
      },
      {
        path: '/loja/atributos/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditAtributo')),
      },
      {
        path: '/loja/tags',
        exact: true,
        component: lazy(() => import('views/TagsList')),
      },
      {
        path: '/loja/tags/adicionar',
        exact: true,
        component: lazy(() => import('views/AddTag')),
      },
      {
        path: '/loja/tags/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditTag')),
      },
      {
        path: '/loja/categorias',
        exact: true,
        component: lazy(() => import('views/CategoriasList')),
      },
      {
        path: '/loja/categoria/adicionar',
        exact: true,
        component: lazy(() => import('views/AddCategoria')),
      },
      {
        path: '/loja/categorias/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditCategoria')),
      },
      {
        path: '/loja/fornecedores',
        exact: true,
        component: lazy(() => import('views/FornecedoresList')),
      },
      {
        path: '/loja/fornecedores/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditFornecedor')),
      },
      {
        path: '/loja/fornecedores/adicionar',
        exact: true,
        component: lazy(() => import('views/AddFornecedor')),
      },
      {
        path: '/loja/fornecedores/frete/:id',
        exact: true,
        component: lazy(() => import('views/FreteList')),
      },
      {
        path: '/loja/estoques',
        exact: true,
        component: lazy(() => import('views/EstoquesList')),
      },
      {
        path: '/loja/estoques/:id',
        exact: true,
        component: lazy(() => import('views/EstoquesSkuList')),
      },
      {
        path: '/loja/estoques/adicionar/:id',
        exact: true,
        component: lazy(() => import('views/AddEstoque')),
      },
      {
        path: '/loja/estoques/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditEstoque')),
      },
      {
        path: '/loja/cupons',
        exact: true,
        component: lazy(() => import('views/CuponsList')),
      },
      {
        path: '/loja/cupons/adicionar',
        exact: true,
        component: lazy(() => import('views/AddCupom')),
      },
      {
        path: '/loja/cupons/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditCupom')),
      },
      {
        path: '/loja/pedidos',
        exact: true,
        component: lazy(() => import('views/PedidosList')),
      },
      {
        path: '/loja/pedidos/adicionar',
        exact: true,
        component: lazy(() => import('views/AddPedido')),
      },
      {
        path: '/loja/pedidos/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditPedido')),
      },
      {
        path: '/loja/pedidos/:id',
        exact: true,
        component: lazy(() => import('views/PedidoView')),
      },
      {
        path: '/usuarios/empresas',
        exact: true,
        component: lazy(() => import('views/CadastradosList')),
      },
      {
        path: '/usuarios/clientes-finais',
        exact: true,
        component: lazy(() => import('views/ClientesAtivosList')),
      },
      {
        path: '/plataforma/estoques',
        exact: true,
        component: lazy(() => import('views/EstoquesGlobaisList')),
      },
      {
        path: '/plataforma/estoques/produtos/:id',
        exact: true,
        component: lazy(() => import('views/ProdutosEstoquesGlobaisList')),
      },
      {
        path: '/plataforma/estoques/produto/adicionar/:id',
        exact: true,
        component: lazy(() => import('views/AddProdutosEstoquesGlobais')),
      },
      {
        path: '/plataforma/estoques/produto/editar/:id',
        exact: true,
        component: lazy(() => import('views/EditProdutosEstoquesGlobais')),
      },
      {
        path: '/configuracoes/parametros',
        isPrivate: true,
        exact: true,
        component: lazy(() => import('views/ParametersView')),
      },
      {
        path: '/configuracoes/perfis',
        exact: true,
        component: lazy(() => import('views/PerfisList')),
      },
      {
        path: '/configuracoes/grupo-usuarios',
        exact: true,
        component: lazy(() => import('views/GroupUsers')),
      },
      {
        path: '/configuracoes/faas/bancos',
        isPrivate: true,
        exact: true,
        component: lazy(() => import('views/BanksFaaS')),
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView,
      },
      {
        path: '/dashboard',
        exact: true,
        component: DashboardDefaultView,
      },
      {
        path: '/sol-leads',
        exact: true,
        component: lazy(() => import('views/Assistant')),
      },
      {
        path: '/playground',
        exact: true,
        component: lazy(() => import('views/playground')),
      },
      {
        component: () => <Redirect to="/dashboard" />,
      },
    ],
  },
]
