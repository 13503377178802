import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffff;
  justify-content: flex-end;
  padding: 4px 12px;
  border-radius: 0px 0px 4px 4px;
  font-size: 12px;
  gap: 4px;

  span {
    font-size: 12px;
  }

  .arrow {
    margin: 0 4px;
  }

  button {
    cursor: pointer;

    color: inherit;
    border: none;
    background-color: transparent;
  }

  button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  button:hover {
    background-color: #f7f7f7;
  }

  .button {
    padding: 4px 8px;
    border-radius: 4px;
  }
`
