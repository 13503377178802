import { Root } from './components/Root'
import { Column } from './components/Column'
import { Row } from './components/Row'
import { Cell } from './components/Cell'
import { Block } from './components/Block'

export const List = {
  Root,
  Column,
  Row,
  Cell,
  Block,
}
