import { USER_ROLE_ENUM, USERS_EMAILS_WITH_PERMISSION } from 'constants/users'
import { useAuth } from 'context/authContext'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRouter({
  layout: Layout,
  component: Component,
  ...rest
}) {
  const { user } = useAuth()
  const isSuperAdmin = user?.role_id === USER_ROLE_ENUM.superAdmin
  const isUserWithPermission = USERS_EMAILS_WITH_PERMISSION.includes(user?.email)
  return (
    <Route
      component={(props) =>
        isSuperAdmin || isUserWithPermission ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
      {...rest}
    />
  )
}
