import { useEffect, useState } from 'react'
import { usePagination } from 'hooks/usePagination'
import { solLeadsService } from 'services/solLeadsService'

export function useSolLeads({ integratorId }) {
  const [leadsHistory, setLeadsHistory] = useState({})
  const [messageHistory, setMessageHistory] = useState('')

  const {
    handleBackPage,
    handleFirstPage,
    handleLastPage,
    handleNextPage,
    pagination,
  } = usePagination(leadsHistory)

  useEffect(() => {
    setMessageHistory('Carregando histórico...')

    solLeadsService
      .solLeadsHistoryCompany(integratorId, { page: pagination.current_page })
      .then(({ data }) => {
        setLeadsHistory(data)

        if (!data?.data?.length) {
          setMessageHistory('Não há nenhum histórico.')
          return
        }

        setMessageHistory('')
      })
  }, [integratorId, pagination.current_page])

  return {
    leadsHistory,
    messageHistory,
    handleBackPage,
    handleFirstPage,
    handleLastPage,
    handleNextPage,
  }
}
