import React, { useEffect } from 'react'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import globalConfigs from 'config/globalConfigs'
import { makeStyles } from '@material-ui/styles'
import { formatDate, formatReal } from 'utils/dataFormat'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Box,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '1440px',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  infosCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    backgroundColor: colors.grey[100],
  },
  textLoading: {
    fontWeight: 'bold',
    marginTop: '20px',
  },
}))

function OrderInvoice({
  items = [],
  order = {},
  invoicingData = {},
  additionalValues = [],
  exportInvoice = false,
  setExportInvoice = () => {},
}) {
  const { logo, address, corporateName } = globalConfigs

  const classes = useStyles()

  useEffect(() => {
    if (exportInvoice) {
      html2canvas(document.querySelector('.orderInvoice'))
        .then((canvas) => {
          const imgWidth = 210
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          const imgData = canvas.toDataURL('image/png')
          const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            compress: true,
          })

          pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight)
          pdf.save(
            `${order.codigo}_Fatura_${order.nome_integrador
              .split(' ')
              .join('_')}${formatDate(new Date())}.pdf`,
          )
        })
        .finally(() => {
          setExportInvoice(false)
        })
    }
  }, [exportInvoice])

  return (
    <Card>
      <div className="orderInvoice">
        <CardContent className={classes.content}>
          <div className="orderInvoiceSection1">
            <CardContent className={classes.content}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Box
                    height={100}
                    component="img"
                    alt="Logo da empresa"
                    src={logo.default}
                  />
                </Grid>
                <Grid item>
                  <Typography align="right" variant="h3">
                    {order.codigo && `Pedido #${order.codigo}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.infosCard}
              >
                <Grid item>
                  <Typography component="h4" gutterBottom variant="overline">
                    Data do pedido
                  </Typography>
                  <Typography>
                    {formatDate(order.data, { mask: true })}
                  </Typography>
                </Grid>
                <Grid item align="right">
                  {order.nome_status !== 'Orçamento' && (
                    <>
                      {order.data_pagamento && (
                        <div data-testid="invoicing-date">
                          <Typography
                            component="h4"
                            gutterBottom
                            variant="overline"
                          >
                            Data de pagamento
                          </Typography>
                          <Typography>
                            {formatDate(order.data_pagamento, { mask: true })}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.infosCard}
              >
                <Grid item>
                  <Typography component="h4" gutterBottom variant="overline">
                    Frete
                  </Typography>
                  <Typography variant="body1">
                    {order.free_shipping
                      ? `Pedido sem frete. Os items adquiridos devem ser retirados junto ao distribuidor, a partir da confirmação de pagamento (mediante a disponibilidade dos equipamentos comprados).`
                      : `O prazo de entrega é de ${order.delivery_days} dias úteis a partir da confirmação de pagamento, mediante a disponibilidade de equipamento. Frete incluso.`}
                  </Typography>
                  <Typography variant="body1">
                    A responsabilidade pela conferência e descarga dos
                    equipamentos, bem como da contratação de pessoas e/ou
                    equipamentos é única e exclusiva do integrador.
                  </Typography>
                </Grid>
              </Grid>

              {order.descricao && (
                <div
                  className={classes.marginTop}
                  data-testid="order-description"
                >
                  <Typography component="h4" gutterBottom variant="overline">
                    Descrição
                  </Typography>
                  <Typography>{order.descricao}</Typography>
                </div>
              )}

              {order.endereco && (
                <div className={classes.marginTop} data-testid="order-address">
                  <Typography component="h4" gutterBottom variant="overline">
                    Endereço de entrega
                  </Typography>
                  <Typography>
                    {order.endereco || ''}, {order.numero || ''}
                    {order.complemento && ` - ${order.complemento}`} -{' '}
                    {order.bairro || ''}
                    <br />
                    {order.cidade || ''} - {order.estado || ''} <br />
                    {order.cep || ''} <br />
                  </Typography>
                </div>
              )}

              {invoicingData.endereco && (
                <div
                  className={classes.marginTop}
                  data-testid="invoicing-address"
                >
                  <Typography component="h4" gutterBottom variant="overline">
                    Faturado para
                  </Typography>
                  <Typography>
                    {invoicingData.nome || ''} <br />
                    {invoicingData.doc || ''} <br />
                    {invoicingData.endereco || ''}, {invoicingData.numero || ''}
                    {invoicingData.complemento &&
                      ` - ${invoicingData.complemento}`}{' '}
                    - {invoicingData.bairro || ''}
                    <br />
                    {invoicingData.cidade || ''} - {invoicingData.estado || ''}{' '}
                    <br />
                    {invoicingData.cep || ''} <br />
                  </Typography>
                </div>
              )}

              <Table className={classes.marginTop}>
                <TableHead
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                >
                  <TableRow>
                    <TableCell style={{ fontFamily: 'NexaRegular' }}>
                      Itens
                    </TableCell>
                    <TableCell />
                    <TableCell
                      style={{ fontFamily: 'NexaRegular', textAlign: 'right' }}
                    >
                      <b>Quantidade</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item, i) => (
                    <TableRow key={i} data-testid="order-items">
                      <TableCell
                        style={{
                          fontFamily: 'NexaRegular',
                          border: 'none',
                          padding: '8px',
                        }}
                      >
                        {item.nome}
                      </TableCell>
                      <TableCell style={{ border: 'none', padding: '8px' }} />
                      <TableCell
                        style={{
                          fontFamily: 'NexaRegular',
                          textAlign: 'right',
                          border: 'none',
                          padding: '8px',
                        }}
                      >
                        <strong style={{ color: '#1335C6', fontSize: '17px' }}>
                          x {item.quantidade}
                        </strong>
                      </TableCell>
                    </TableRow>
                  ))}
                  {additionalValues.map((item, i) => (
                    <TableRow key={i} data-testid="additional-values">
                      <TableCell style={{ border: 'none', padding: '8px' }} />
                      <TableCell
                        style={{
                          fontFamily: 'NexaRegular',
                          border: 'none',
                          padding: '8px',
                        }}
                      >
                        {item.descricao}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontFamily: 'NexaRegular',
                          border: 'none',
                          padding: '8px',
                        }}
                      >
                        {item.tipo === 'desconto' ? '-' : ''}{' '}
                        {formatReal(item.valor, {
                          static: true,
                          symbol: 'currency',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </div>
          <div className="orderInvoiceSection2">
            <CardContent className={classes.content}>
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                style={{
                  height: '200px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
              >
                <Grid md={6} item>
                  <div
                    style={{
                      padding: '30px 60px',
                      backgroundColor: '#f5f5f5',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Box marginBottom={2}>
                        <img
                          alt="Logo da empresa"
                          src={logo.default}
                          height={50}
                          width={140}
                        />
                      </Box>
                      <Typography>
                        {corporateName}
                        <br />
                        {address.road}, {address.number}{' '}
                        {address.complement && `- ${address.complement}`}
                        <br />
                        {address.district} - {address.city}
                        <br />
                        {address.state} - {address.cep}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid md={6} item>
                  <Box
                    height="100%"
                    padding="30px 60px"
                    display="flex"
                    alignItems="end"
                    flexDirection="column"
                    justifyContent="center"
                    border="solid #F2F2F2"
                  >
                    <Box display="flex" marginY={1}>
                      <Typography variant="h5">Subtotal:</Typography>
                      &nbsp;
                      <Typography>
                        <strong>
                          {formatReal(order.subtotal, {
                            static: true,
                            symbol: 'currency',
                          })}
                        </strong>
                      </Typography>
                    </Box>
                    <Box display="flex" marginY={1}>
                      <Typography variant="h5">Desconto:</Typography>
                      &nbsp;
                      <Typography>
                        <strong>
                          {formatReal(order.desconto, {
                            static: true,
                            symbol: 'currency',
                          })}
                        </strong>
                      </Typography>
                    </Box>
                    <Box display="flex" marginY={1}>
                      <Typography variant="h5">Total:</Typography>
                      &nbsp;
                      <Typography
                        style={{
                          color: '#1335C6',
                          fontSize: '20px',
                          fontWeight: 'bold',
                        }}
                      >
                        <strong>
                          {formatReal(order.total, {
                            static: true,
                            symbol: 'currency',
                          })}
                        </strong>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </CardContent>
      </div>
    </Card>
  )
}

export default OrderInvoice
