import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { AppBar, Button, IconButton, Toolbar, Hidden } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import globalConfigs from 'config/globalConfigs'
import useRouter from 'utils/useRouter'
import { useStyles } from './styles'

function TopBar(props) {
  const { logo } = globalConfigs
  const { onOpenNavBarMobile, className, ...rest } = props

  const classes = useStyles()
  const { history } = useRouter()
  const dispatch = useDispatch()

  const handleLogout = () => {
    localStorage.removeItem('tokenGestao')
    localStorage.removeItem('userId')
    localStorage.removeItem('tokenAssistant')
    dispatch({
      type: 'INITIAL',
    })
    history.push('/auth/login')
  }

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={logo.white} className={classes.logo} />
        </RouterLink>
        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            Sair
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
}

export default TopBar
