import moment from 'moment'

moment.locale('pt-br')

/** All configs */
const CONFIGS = {
  DEFAULT: {
    mask: false,
    length: 0,
  },
  DATE: {
    mask: false,
    format: 'DD/MM/YYYY',
  },
  REAL: {
    mask: false,
    static: false,
    locale: 'pt-br',
    currency: 'BRL',
    symbol: 'currency',
    decimal: 2,
  },
  PERCENTAGE: {
    mask: false,
    static: false,
    symbol: false,
    locale: 'pt-br',
    decimal: undefined,
    min: undefined,
    max: 100,
  },
  CEP: {
    mask: false,
    length: 8,
  },
  CPF: {
    mask: false,
    length: 11,
  },
  CNPJ: {
    mask: false,
    length: 14,
  },
  PHONE: {
    mask: false,
    length: 10,
  },
  CELL_PHONE: {
    mask: false,
    length: 11,
  },
}

/** Format date */
export const formatDate = (value = '', config = CONFIGS.DATE) => {
  config = { ...CONFIGS.DATE, ...config }

  if (moment(value).isValid()) {
    if (config.mask) {
      return moment(value).format(config.format)
    }
    return moment(value).format('YYYY-MM-DD')
  }
  return value
}

/** Format real */
export const formatReal = (value = '', config = CONFIGS.REAL) => {
  config = { ...CONFIGS.REAL, ...config }

  if (config.static) {
    return Number(value || 0).toLocaleString(config.locale, {
      style: config.symbol,
      currency: config.currency,
      maximumFractionDigits: config.decimal,
      minimumFractionDigits: config.decimal,
    })
  }

  if (config.mask) {
    return String(value)
      .replace(/(?![-.])(\D)/g, '')
      .replace(/\./, ',')
      .replace(new RegExp(`(\\d+\\,\\d{${config.decimal}})(.*)`, 'g'), '$1')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  return String(value)
    .replace(/(?!^[-])(\D)/g, '')
    .replace(new RegExp(`(\\d)(\\d{${config.decimal}})$`), '$1.$2')
}

/** Format Percent */
export const formatPercentage = (value = '', config = CONFIGS.PERCENTAGE) => {
  config = { ...CONFIGS.PERCENTAGE, ...config }

  if (config.static) {
    return Number((value || 0) / 100).toLocaleString(config.locale, {
      maximumFractionDigits: config.decimal,
      minimumFractionDigits: config.decimal,
      ...(config.symbol && { style: 'percent' }),
    })
  }

  const formattedValue = String(value).replace(/\,/, '.')

  if (parseFloat(formattedValue) > config.max) return config.max
  if (parseFloat(formattedValue) < config.min) return config.min

  if (config.mask) {
    return String(formattedValue)
      .replace(/(?![.])(\D)/g, '')
      .replace(/\./, ',')
      .replace(/(\d*\,\d*)(.*)/, '$1')
      .replace(new RegExp(`(\\d*\\,\\d{${config.decimal}})(.*)`), '$1')
  }
  return String(formattedValue)
    .replace(/(?![.])(\D)/g, '')
    .replace(/(\d*\.\d*)(.*)/, '$1')
    .replace(new RegExp(`(\\d*\\\.\\d{${config.decimal}})(.*)`), '$1')
}

/** Format CPF */
export const formatCPF = (value = '', config = CONFIGS.CPF) => {
  config = { ...CONFIGS.CPF, ...config }

  if (config.mask) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{11})\d+?$/, '$1')
}

/** Format CNPJ */
export const formatCNPJ = (value = '', config = CONFIGS.CNPJ) => {
  config = { ...CONFIGS.CNPJ, ...config }

  if (config.mask) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{14})\d+?$/, '$1')
}

/** Format CEP */
export const formatCEP = (value = '', config = CONFIGS.CEP) => {
  config = { ...CONFIGS.CEP, ...config }

  if (config.mask) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{8})\d+?$/, '$1')
}

/** Format phone */
export const formatPhone = (value = '', config = CONFIGS.PHONE) => {
  config = { ...CONFIGS.PHONE, ...config }

  if (config.mask) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{10})\d+?$/, '$1-$2')
}

/** Format cell */
export const formatCellPhone = (value = '', config = CONFIGS.CELL_PHONE) => {
  config = { ...CONFIGS.CELL_PHONE, ...config }

  if (config.mask) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{11})\d+?$/, '$1-$2')
}

/** Detect format */
export const detectFormat = (
  value = '',
  detect = [],
  config = CONFIGS.DEFAULT,
) => {
  const valueLength = String(value).replace(/\D/g, '').length

  const detected = detect.reduce((acc = detect[0], value) => {
    if (detect.includes(value)) {
      return valueLength > CONFIGS[acc].length ? value : acc
    }
  })

  config = { ...CONFIGS[detected], ...config }

  return {
    CEP: formatCEP,
    CPF: formatCPF,
    CNPJ: formatCNPJ,
    PHONE: formatPhone,
    CELL_PHONE: formatCellPhone,
  }[detected](value, config)
}
