import API from 'api'

export const faasService = {
  async getBanksFaas(signal) {
    const response = await API.get(`banks/faas`, {
      cancelToken: signal,
    })
    return response.data
  },
  async getProvidersFaas(signal) {
    const response = await API.get(`providers/select/faas`, {
      cancelToken: signal,
    })
    return response.data
  },

  async getFieldsValueFaas({ providerId, bankId }) {
    const response = await API.post(`banks/integration-fields-values`, {
      provider_id: providerId,
      bank_id: bankId,
    })
    return response.data
  },
  async updateIntegrationConfigsFaas(data) {
    const response = await API.put(`banks/integration-configs`, data)
    return response
  },
}
