import axios from 'axios'

const URL = process.env.REACT_APP_API

const API = axios.create({
  baseURL: URL,
})

API.interceptors.request.use(function (config) {
  const token = localStorage.getItem('tokenGestao') || null
  token && (config.headers.token = token)
  return config
})

API.interceptors.response.use((response) => {
  if (
    response.status >= 200 &&
    response.status <= 299 &&
    typeof response.data === 'string' &&
    response.config.method === 'get'
  ) {
    let finalDecryptedText = response.data
    for (let i = 0; i < 2; i++) {
      if (i % 2 === 0) {
        finalDecryptedText = Buffer(finalDecryptedText, 'base64').toString()
      } else {
        finalDecryptedText = Buffer(
          finalDecryptedText.split('').reverse().join(''),
          'base64',
        ).toString()
      }
    }
    finalDecryptedText = JSON.parse(finalDecryptedText)
    return {
      ...response,
      data: JSON.parse(finalDecryptedText),
    }
  }
  return response
})

export default API
