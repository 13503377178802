import React, { createContext, useContext, useMemo, useState } from 'react'

const IntegratorModalContext = createContext({})

function IntegratorModalProvider({ children }) {
  const [isIntegratorModalOpen, setIsIntegratorModalOpen] = useState({
    isOpen: false,
    integratorId: null,
  })

  const contextValue = useMemo(
    () => ({
      isIntegratorModalOpen,
      setIsIntegratorModalOpen,
    }),
    [isIntegratorModalOpen, setIsIntegratorModalOpen],
  )

  return (
    <IntegratorModalContext.Provider value={contextValue}>
      {children}
    </IntegratorModalContext.Provider>
  )
}

const useIntegratorModalContext = () => {
  const context = useContext(IntegratorModalContext)
  return context
}

export { useIntegratorModalContext, IntegratorModalProvider }
