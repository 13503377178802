import storeSynchronize, { defineState } from 'redux-localstore';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

const INITIAL = {
  SESSION: {
    loggedIn: false,
    first_name: '',
    last_name: '',
    avatar: '',
    bio: '',
    role: '' /** GUEST || USER || ADMIN */
  }
};

const initialState = defineState(INITIAL)('reducer');
const sessionReducer = (state = initialState, { type, data }) => {
  if (type === 'INITIAL') {
    return initialState;
  } else if (state[type]) {
    return {
      ...state, [type]: {
        ...state[type], ...data
      },
    };
  }
  return state;
};

const store = configureStore({
  reducer: combineReducers({
    reducer: sessionReducer
  })
});
storeSynchronize(store);

export default store;