import React from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'

export function Loading() {
  const classes = useStyles()

  return (
    <center className={classes.loading}>
      <CircularProgress
        disableShrink
        size={30}
        thickness={4}
        className={classes.loadingCircular}
      />
      <Typography className={classes.loadingText}>
        Carregando logs...
      </Typography>
    </center>
  )
}
