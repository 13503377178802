import React from 'react'
import * as S from './styles'

export function Key({ preText, value }) {
  return (
    <S.Quote>
      <span>
        <strong>{preText}: </strong>
      </span>
      <span>{value}</span>
    </S.Quote>
  )
}
