/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { IntegratorModal } from 'components'
import { useIntegratorModalContext } from 'context/IntegratorModal'

export function GlobalModals() {
  const { isIntegratorModalOpen, setIsIntegratorModalOpen } =
    useIntegratorModalContext()

  return (
    <>
      {isIntegratorModalOpen?.isOpen && (
        <IntegratorModal
          integratorId={isIntegratorModalOpen?.integratorId}
          openModalIntegrator={isIntegratorModalOpen?.isOpen}
          onClose={() => {
            setIsIntegratorModalOpen({
              isOpen: false,
              integratorId: null,
            })
          }}
        />
      )}
    </>
  )
}
