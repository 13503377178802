import React from 'react'

export function BasicInfo({ logs }) {
  return (
    <div>
      {logs.nome && (
        <span>
          <strong>Usuário:</strong> {logs.nome}
        </span>
      )}
      {logs.created_at && (
        <span>
          <strong>Atualizado em:</strong> {logs.created_at}
        </span>
      )}
    </div>
  )
}
