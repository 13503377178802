import React from 'react'
import { TextField, MenuItem, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { OPTIONS } from '../../constants'
import { ChooseProvider } from './components/ChooseProvider'
import { useUpdateUserInfo } from './hooks/useUpdateUserInfo'
import * as S from './styles'

export function Form({
  setVendedor,
  vendedor,
  vendedoresArray,
  infoIntegrador,
  setUserBlock,
  userBlock,
}) {
  const {
    discountRules,
    handleSubmit,
    setDiscount,
    discount,
    providerID,
    setProviderID,
    userType,
    setUserType,
  } = useUpdateUserInfo(infoIntegrador, vendedor, userBlock)

  return (
    <S.Container>
      <Autocomplete
        onChange={(_, newValue) => {
          if (newValue != null) {
            setVendedor(newValue)
          }
        }}
        value={vendedor || { nome: '', email: '', razaoSocial: '' }}
        size="small"
        options={vendedoresArray}
        getOptionLabel={(option) =>
          `${option.nome} - ${option.email} - ${option.razaoSocial}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione o responsável"
            variant="outlined"
          />
        )}
      />

      <TextField
        select
        fullWidth
        size="small"
        variant="outlined"
        label="Plano"
        defaultValue={infoIntegrador.user.plano}
        value={discount}
        onChange={(_, { props }) => {
          setDiscount(props.value)
        }}
      >
        {discountRules.map((discountRule) => (
          <MenuItem key={discountRule.id} value={discountRule.id}>
            {discountRule.percentual}% - {discountRule.regra}
          </MenuItem>
        ))}
      </TextField>

      <ChooseProvider
        providerID={providerID}
        setProviderID={setProviderID}
        userType={userType}
        setUserType={setUserType}
      />

      <TextField
        select
        size="small"
        variant="outlined"
        label="Bloquear usuário"
        value={userBlock}
        onChange={(_, { props }) => {
          setUserBlock(props.value)
        }}
      >
        {OPTIONS.BLOCK.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>

      <Button onClick={handleSubmit} variant="contained" color="primary">
        Salvar
      </Button>
    </S.Container>
  )
}
