import React from 'react'
import { CircularProgress } from '@material-ui/core'

import * as S from './styles'

export function LoadingList({ withoutBackground = false }) {
  return (
    <S.Container withoutBackground={withoutBackground}>
      <div className="list">
        <S.LoadingContainer>
          <CircularProgress
            disableShrink
            size={30}
            thickness={4}
            style={{ color: 'grey' }}
          />
          <span>Carregando...</span>
        </S.LoadingContainer>
      </div>
    </S.Container>
  )
}
