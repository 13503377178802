import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '85%',
  },
  loadingCircular: {
    color: 'gray',
    marginTop: '20px',
  },
  loadingText: {
    color: 'gray',
    paddingTop: '10px',
  },
}))
