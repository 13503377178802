import React from 'react'
import { Tooltip } from '@material-ui/core'
import { ArrowChevronBackIcon, ArrowChevronForwardIcon } from '@77sol/icons'
import * as S from './styles'

export function Header({ page, lastPage, setPage, hasMoreLogs, ...props }) {
  return (
    <S.Container {...props}>
      <h5>Registro de Alterações</h5>
      <S.Pagination page={page} lastPage={lastPage}>
        <Tooltip title="Pagina Anterior">
          <div>
            <ArrowChevronBackIcon
              width="20px"
              className="arrowBack"
              data-testid="back-arrow"
              onClick={() =>
                page !== 1 && page - 1 <= lastPage && setPage(page - 1)
              }
            />
          </div>
        </Tooltip>
        <span data-testid="logs-pages">
          {page} / {lastPage}
        </span>
        <Tooltip title="Próxima Página">
          <div>
            <ArrowChevronForwardIcon
              width="20px"
              className="arrowForward"
              data-testid="forward-arrow"
              onClick={() =>
                hasMoreLogs && page + 1 <= lastPage && setPage(page + 1)
              }
            />
          </div>
        </Tooltip>
      </S.Pagination>
    </S.Container>
  )
}
