import styled from 'styled-components'

export const Paper = styled.div`
  min-height: auto;
  min-width: auto;
  border-radius: 4px;
  background-color: #ffffff;
  padding: ${({ hasPadding }) => (hasPadding ? '20px' : '0')};
  box-shadow: ${({ canShowBorder }) =>
    canShowBorder ? '0 0 0 1px #3f3f440d, 0 1px 3px 0 #3f3f4426' : 'none'};
`
