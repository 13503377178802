import { ScrollReset } from 'components'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { appRoutes } from 'routes/appRoutes'
import { GlobalMessage } from 'GlobalMessage'
import { GlobalModals } from 'GlobalModals'
import PrivateRouter from './PrivateRouter'

function AppRoutes() {
  return (
    <Router>
      <GlobalMessage />
      <GlobalModals />
      <ScrollReset />
      <Switch>
        {appRoutes.map(({ layout: Layout, routes }) =>
          routes.map(
            ({
              path: subPath,
              exact: subExact,
              component: Component,
              isPrivate = false,
            }) =>
              isPrivate ? (
                <PrivateRouter
                  path={subPath}
                  exact={subExact}
                  key={subPath}
                  layout={Layout}
                  component={Component}
                />
              ) : (
                <Route
                  key={subPath}
                  path={subPath}
                  exact={subExact}
                  component={(props) => (
                    <Layout {...props}>
                      <Component {...props} />
                    </Layout>
                  )}
                />
              ),
          ),
        )}
      </Switch>
    </Router>
  )
}

export default AppRoutes
