import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;

  border-radius: 4px;
  box-shadow: #eee 0px 0px 0px 1px;
`

export const List = styled.div`
  display: table;

  width: 100%;

  font-size: 14px;
  font-weight: 400;
`

export const Column = styled.div`
  display: table-row;

  width: 100%;

  span {
    display: table-cell;

    font-weight: bold;
    white-space: nowrap;
    vertical-align: middle;

    padding: 8px 16px;
    border-bottom: 1px solid #eee;
    background-color: #fafafa;

    &:not(:first-child) {
      min-width: 140px;
    }
  }
`

export const Row = styled.div`
  display: table-row;

  width: 100%;

  span {
    display: table-cell;
    vertical-align: middle;

    padding: 8px 16px;
    border-bottom: 1px solid #eee;
  }

  &:last-child {
    span {
      border-bottom: none;
    }
  }
`

export const Block = styled.div`
  display: table-caption;
  caption-side: ${({ position }) => position};
`
