import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  > h5 {
    font-size: 16px;
    color: #707072;
  }

  span {
    font-size: 12px;
  }
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;

  .arrowBack {
    margin-right: 10px;
    cursor: ${({ page }) => (page === 1 ? 'not-allowed' : 'pointer')};
    color: ${({ page }) => (page === 1 ? '#c6c6cd' : '#5f5f63')};
  }

  .arrowForward {
    margin-left: 10px;
    cursor: ${({ lastPage, page }) =>
      page === lastPage ? 'not-allowed' : 'pointer'};
    color: ${({ lastPage, page }) =>
      page === lastPage ? '#c6c6cd' : '#5f5f63'};
  }
`
