import React, { useEffect, useState } from 'react';
import { defaults } from 'react-chartjs-2';
import { Line, Bar } from 'react-chartjs-2';
import { barOptions, lineOptions, lineOptionsCurrency, barOptionsCurrency } from './graphOptions';

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  

function DashGraphComponent({ data, labels, type, title, format, multiple }){

    const [finalData, setFinalData] = useState({});

    useEffect(() => {
        defaults.global.defaultFontFamily = 'Nexa';
        defaults.global.defaultFontSize = 12;

        setFinalData({
            labels: labels,
            datasets: multiple ?
            Object.values(data).map(item => {
                let color = getRandomColor();
                return (
                    {
                        label: item.label,
                        data: item.values,
                        fill: false,
                        backgroundColor: color,
                        borderColor: color,
                        yAxisID: 'y-axis-1',
                        interaction: {
                            intersect: true
                        },
                        radius: 1,
                    }
                )
            })
            :
            [
                {
                    label: title,
                    data: data,
                    fill: false,
                    backgroundColor: '#7fa2e3',
                    borderColor: [],
                    yAxisID: 'y-axis-1',
                    interaction: {
                        intersect: true
                    },
                    radius: 1,
                }
            ]
        })

    }, [data])

    return (
        <div>
            {type == 'line' &&
                <Line height={400} data={finalData} options={format != 'currency' ? lineOptions : lineOptionsCurrency} />
            }
            {type == 'bar' &&
                <Bar height={250} data={finalData} options={format != 'currency' ? barOptions : barOptionsCurrency} />
            }
        </div>
    )
};

export default DashGraphComponent;
