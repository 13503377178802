import React, { createContext, useContext, useMemo, useState } from 'react'

const INITIAL_MESSAGE_CONTEXT = {
  open: false,
  type: '',
  text: '',
  time: 5000,
}

const MessageContext = createContext({
  message: INITIAL_MESSAGE_CONTEXT,
  setMessage: () => {},
})

export function MessageProvider({ children }) {
  const [message, setMessage] = useState(INITIAL_MESSAGE_CONTEXT)

  const values = useMemo(() => {
    return {
      message,
      setMessage,
    }
  }, [message, setMessage])

  return (
    <MessageContext.Provider value={values}>{children}</MessageContext.Provider>
  )
}

export function useMessageContext() {
  return useContext(MessageContext)
}
