import { useMessage } from 'hooks/useMessage'
import { useEffect, useState } from 'react'
import API from 'api'
import { z } from 'zod'
import { UserInfo } from '../validators/scheme'
import { USER_TYPE_ENUM } from '../constants'

export const useUpdateUserInfo = (infoIntegrador, vendedor, userBlock) => {
  const [discountRules, setDiscountRules] = useState([])
  const [discount, setDiscount] = useState(infoIntegrador.user.plano)

  const [providerID, setProviderID] = useState(
    infoIntegrador.user.provider_id || null,
  )
  const [userType, setUserType] = useState(
    infoIntegrador.user.user_type || USER_TYPE_ENUM.INTEGRATOR,
  )

  const { onSuccessMessage, onErrorMessage } = useMessage()

  useEffect(() => {
    API.get('/gestao/descontos').then(({ data }) => {
      setDiscountRules(data.descontos)
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      seller: vendedor.id,
      discount: Number(discount),
      block_user: userBlock,
      user_type: userType,
      ...(providerID && {
        provider_id: Number(providerID),
      }),
    }

    try {
      const parsedData = UserInfo.parse(data)

      API.put(`/user-info/${infoIntegrador.user.id}`, parsedData)
        .then(() => onSuccessMessage('Usuário atualizado com sucesso!'))
        .catch(() =>
          onErrorMessage(
            'Ocorreu um erro ao tentar atualizar as informações do usuário.',
          ),
        )
    } catch (err) {
      if (err instanceof z.ZodError) {
        onErrorMessage(err.issues[0].message)
      }
    }
  }

  return {
    discountRules,
    handleSubmit,
    setDiscount,
    discount,
    providerID,
    setProviderID,
    userType,
    setUserType,
  }
}
